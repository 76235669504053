<bolt-accordion-group>
  <bolt-accordion *ngIf="!readOnly" header="+ Create New" class="bolt-background-vibrant-blue" #accordion>
    <div>
      <div style="display: flex; align-items: baseline">
        <bolt-button
          [disabled]="this.categoryDisabled"
          [ngStyle]="{ width: categoryDisabled ? '40%' : '100%' }"
          type="primary"
          href="/create/category"
          #button
        >
          <div class="bolt-body-copy-lg">Category</div>
        </bolt-button>
        <bolt-contextual-help *ngIf="this.categoryDisabled" slot="help" type="push" heading="Required">
          Remove an enabled Category with Mobile Section to create new Category.
        </bolt-contextual-help>
      </div>

      <bolt-button width="full" type="primary" href="/create/product" #button><div class="bolt-body-copy-lg">Product</div></bolt-button>
      <bolt-button width="full" type="primary" href="/create/perk" #button><div class="bolt-body-copy-lg">Perk</div></bolt-button>
      <bolt-button width="full" type="primary" href="/create/recommended" #button
        ><div class="bolt-body-copy-lg">Recommended for you</div></bolt-button
      >
    </div>
  </bolt-accordion>

  <div *ngIf="categories && products && perks && recommendedTiles">
    <bolt-accordion class="accordion-width" header="Category" #accordion>
      <bolt-button width="full" type="tertiary" *ngFor="let category of categories" (click)="navigate(category, 'category')" #button
        >{{ category.categoryName }}
      </bolt-button>
    </bolt-accordion>
    <bolt-accordion class="accordion-width" header="Product" #accordion>
      <bolt-button width="full" type="tertiary" *ngFor="let product of products" (click)="navigate(product, 'product')" #button>{{
        product.productName
      }}</bolt-button>
    </bolt-accordion>
    <bolt-accordion class="accordion-width" header="Perk" #accordion>
      <bolt-button width="full" type="tertiary" *ngFor="let perk of perks" (click)="navigate(perk, 'perk')" #button>
        {{ perk.perkName }}
      </bolt-button>
    </bolt-accordion>
    <bolt-accordion class="accordion-width" header="Recommended for you" #accordion>
      <bolt-button
        width="full"
        type="tertiary"
        *ngFor="let recommendedTile of recommendedTiles"
        (click)="navigate(recommendedTile, 'recommended')"
        #button
        >{{ recommendedTile.recommendedTileName }}
      </bolt-button>
    </bolt-accordion>
  </div>
  <div *ngIf="!categories || !products || !perks || !recommendedTiles" class="top-padding text-center"><em>Loading data...</em></div>
</bolt-accordion-group>
