import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  const router = inject(Router);
  const incomingState = router.getCurrentNavigation()?.extras?.state;

  if (incomingState) {
    return true;
  } else {
    router.navigate(['/home']);
    return false;
  }
};
