import { NgModule } from '@angular/core';
import { AuthModule, StsConfigLoader } from 'angular-auth-oidc-client';
import { AuthService } from './auth.service';

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: (authService: AuthService) => authService.createConfigLoader(),
        deps: [AuthService],
      },
    }),
  ],
  exports: [AuthModule],
  providers: [AuthService],
})
export class AuthConfigModule {}
