export type Filter = {
  title: string;
  icon: string;
};

export enum BenefitType {
  Perk = 'perk',
  Reward = 'reward',
}

// export type PolicyFilterType = {
//   [key in BenefitType]: Filter[];
// };

export const perkFilters: Filter[] = [
  { title: 'Car and travel', icon: 'car' },
  { title: 'Home', icon: 'home' },
  { title: 'Family, health & wellness', icon: 'government' },
  { title: 'Pet', icon: 'paw' },
  { title: 'Planning for tomorrow', icon: 'government' },
];

// export const rewardFilters: Filter[] = [
//   { title: 'Auto', icon: 'car' },
//   { title: 'Home', icon: 'home' },
//   { title: 'Pet', icon: 'paw' },
// ];

// export const PolicyFilters: PolicyFilterType = { perk: perkFilters, reward: rewardFilters };
