import { Icon } from './icon';

export interface Category {
  id: string;
  categoryName: string;
  subtext: string;
  icon: Icon;
  enabled: boolean;
  effectiveDate: string;
  rolloutPercentage: number;
  displayInMobile: boolean;
  displayInWeb: boolean;
  orderOfDisplay?: number;
  tileDisplay: boolean;
  userName: string;
  userId: string;
  revisionComment?: string;
}
