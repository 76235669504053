export interface Configuration {
  api: string;
  env: string;
  secrets: SecretConfig;
}

export interface SecretConfig {
  clientId: string;
  url: string;
  ldap: string;
  oidc: OidcConfiguration;
}

export interface OidcConfiguration {
  authMethod: string;
  authority: string;
  realm: string;
  ttl: number;
}
