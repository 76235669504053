<bolt-header layout="fluid" class="header" breakpoint="100px">
  <ul slot="utility-links">
    <bolt-button type="tertiary" onClick="window.customModal.openModal()">Log Out</bolt-button>
  </ul>
</bolt-header>

<bolt-modal id="customModal" heading="Log Out?">
  <p>Are you sure you want to log out?</p>
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button slot="forward" type="primary" href="https://inside.nationwide.com">Logout</bolt-button>
    <bolt-button slot="back" onclick="window.customModal.closeModal()">Cancel</bolt-button>
  </bolt-button-bar>
</bolt-modal>
<router-outlet></router-outlet>
