import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Configuration } from './types/configuration';

export class ConfigurationInitializationError extends Error {}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config?: Configuration;

  constructor(private readonly http: HttpClient) {}

  load() {
    return this.http.get<Configuration>('/assets/appConfig.json').pipe(
      tap((config) => {
        this.config = config;
      }),
    );
  }

  get app() {
    if (!this.config) {
      throw new ConfigurationInitializationError();
    }

    return this.config;
  }
}
