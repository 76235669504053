import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, DefaultUrlSerializer, Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';
import { RevisionService } from 'src/app/services/revision.service';
import {
  defaultMaxDate,
  effectiveDateValidator,
  locationValidator,
  revisionValidator,
  rolloutPercentageValidator,
  shouldDisplayError,
} from 'src/app/shared/validators';
import { ICONS, IIQ_LINK, MobileSectionNames } from '../../shared/constants';
import { Category } from '../../shared/types/categories';
import { Perk } from '../../shared/types/perks';
import { Product } from '../../shared/types/products';
import { RecommendedTile } from '../../shared/types/recommendedTile';
import { Revision } from '../../shared/types/revision';
import { CategoryService } from '@services';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, OnDestroy {
  urlSerializer = new DefaultUrlSerializer();
  products: Product[] | undefined = undefined;
  categories: Category[] | undefined = undefined;
  category: Category | undefined = undefined;
  buttonBar = true;
  isCreate = false;
  hideForm = false;
  readOnly = true;
  openModal = false;
  arraySettled = true;
  openRequestErrorModal = false;
  link = IIQ_LINK;
  product: Product = {} as Product;
  selectedProduct = '';
  icons = ICONS;
  revisions: Observable<Revision[]> = of([]);
  maxDate: string | null = defaultMaxDate;
  shouldDisplayError = shouldDisplayError;
  private subscriptions: Subscription = new Subscription();
  hasUnsavedChanges = true;
  openNavModal = false;
  navObject = { object: {} };
  navPath = '';

  constructor(
    private router: Router,
    private productService: ProductService,
    private location: Location,
    private route: ActivatedRoute,
    private revisionService: RevisionService,
    private categoryService: CategoryService,
  ) {
    const createCheck = this.router.url.match('^/create/*') != null;
    this.buttonBar = createCheck;
    this.isCreate = createCheck;
  }

  productForm = new FormGroup({
    productName: new FormControl(''),
    subtext: new FormControl(''),
    icon: new FormControl({ value: { type: '', data: '' }, disabled: false }),
    enabled: new FormControl(false),
    effectiveDate: new FormControl('', effectiveDateValidator),
    rolloutPercentage: new FormControl<number | string>('', rolloutPercentageValidator),
    categoryId: new FormControl(''),
    locationGroup: new FormGroup(
      {
        displayInMobile: new FormControl(false),
        displayInWeb: new FormControl(false),
      },
      locationValidator,
    ),
  });

  revisionForm = new FormGroup({
    revisionComment: new FormControl('', revisionValidator),
  });

  toggleCheckbox(control: FormControl) {
    control.patchValue(!control.value);
    control.markAsTouched();
    this.openModal = false;
  }

  modalCancelToggle() {
    this.productForm.controls.enabled.patchValue(true);
    this.openModal = false;
  }

  openStatusModal(event: MouseEvent) {
    if (this.productForm.controls['enabled'].value) {
      event.preventDefault();
      this.openModal = true;
    }
  }

  async edit(editObject: { active: boolean; object: Category | Product | Perk | RecommendedTile }) {
    const product = this.product ? this.product : (editObject.object as Product);
    if (editObject.active) {
      this.router.navigateByUrl('/product/edit', { state: product });
    }
  }

  async nav(navObject: { object: Category | Product | Perk | RecommendedTile }) {
    if ((this.router.url.match('^/product/edit') || this.isCreate) && this.productForm.touched) {
      this.openNavModal = true;
      this.navObject.object = navObject.object;
    } else {
      this.hasUnsavedChanges = false;
      this.product = navObject.object as Product;
      if (this.product.id && this.readOnly) this.revisions = this.revisionService.getRevisions('products', this.product.id);
      this.highlightProductCheck();
    }
  }

  async save(activated: boolean) {
    if (activated) {
      const { locationGroup, ...productForm } = this.productForm.value;
      this.product = { ...this.product, ...productForm, ...locationGroup } as Product;
      [...Object.values(this.productForm.controls), ...Object.values(this.revisionForm.controls)].forEach((c: AbstractControl) => {
        c.markAsDirty();
        c.markAsTouched();
        c.updateValueAndValidity();
      });
      if (this.productForm.valid && (this.revisionForm.valid || this.isCreate) && this.products) {
        this.products.forEach((current, index) => {
          if (this.products) {
            if (this.products.indexOf(current) != current.orderOfDisplay - 1) {
              index += 1;
              current.orderOfDisplay = index;
              if (current.id != this.product.id && current.id != '-1') {
                const revisionComment = 'Updated products order of display';
                this.subscriptions.add(this.productService.saveProduct(current, revisionComment).subscribe());
              } else {
                this.product.orderOfDisplay = index;
              }
            }
          }
        });
        const revisionComment = this.revisionForm.value.revisionComment as string;
        (window as any).requestIndicator.show();
        this.subscriptions.add(
          this.productService.saveProduct(this.product, revisionComment).subscribe({
            next: (product) => {
              this.product = product;
              this.buttonBar = false;
              this.hasUnsavedChanges = false;
              this.highlightProductCheck();
              (window as any).requestIndicator.hide();
              this.router.navigateByUrl('/product/view', { state: this.product });
            },
            error: () => {
              (window as any).requestIndicator.hide();
              this.openRequestErrorModal = true;
            },
          }),
        );
      }
    }
  }

  async delete(activated: boolean) {
    if (activated) {
      (window as any).requestIndicator.show();
      this.subscriptions.add(
        this.productService.deleteProduct(this.product).subscribe({
          next: () => {
            (window as any).requestIndicator.hide();
            this.router.navigateByUrl(`/home`);
          },
          error: () => {
            (window as any).requestIndicator.hide();
            this.openRequestErrorModal = true;
          },
        }),
      );
      this.reorderProducts();
    }
  }

  reorderProducts() {
    if (this.products) {
      this.products.forEach((current) => {
        if (this.product.orderOfDisplay <= current.orderOfDisplay) {
          current.orderOfDisplay = current.orderOfDisplay - 1;
          if (current.id != this.product.id && current.id != '-1') {
            const revisionComment = 'Changed display order';
            this.subscriptions.add(this.productService.saveProduct(current, revisionComment).subscribe());
          }
        }
      });
    }
  }

  ngOnInit() {
    this.subscriptions.add(
      this.categoryService.$categories.subscribe((res) => {
        this.categories = res.filter((category) => category.mobileSection != MobileSectionNames.Tile);
        this.readOnly = sessionStorage.getItem('readOnly') === 'false' ? false : true;
        this.product = this.location.getState() as Product;
        if (this.router.url.match('^/product/view')) {
          this.category = this.categories?.find((category) => category.id === this.product.categoryId);
          this.setNewProduct();
        }
        this.highlightProductCheck();
        if (this.product.id && this.readOnly) this.revisions = this.revisionService.getRevisions('products', this.product.id);
        this.editCheck();
      }),
    );
  }

  checkRoute(products: Product[]) {
    if (this.router.url.match('^/product/view') && !this.product.productName) {
      const name = this.route.snapshot.paramMap.get('name');

      const foundProduct = products.find((product) => {
        return product.productName === name;
      });

      if (foundProduct) {
        this.product = foundProduct;
      } else {
        this.router.navigateByUrl('');
      }
    }
  }

  editCheck() {
    if (this.product != null && this.router.url.match('^/product/edit')) {
      this.buttonBar = true;
      setTimeout(() => {
        this.productForm.setValue({
          productName: this.product.productName,
          subtext: this.product.subtext,
          icon: this.product.icon,
          enabled: this.product.enabled,
          effectiveDate: this.product.effectiveDate,
          rolloutPercentage: this.product.rolloutPercentage,
          categoryId: this.product.categoryId ?? '',
          locationGroup: {
            displayInMobile: this.product.displayInMobile || false,
            displayInWeb: this.product.displayInWeb || false,
          },
        });
        this.setNewProduct();
      });
    } else {
      // prevent checkbox toggling animation on edit page load
      // by defaulting to false and then setting to true if not edit mode
      setTimeout(() => this.productForm.controls.enabled.setValue(true));
    }
  }

  drop(event: CdkDragDrop<Product[]>) {
    moveItemInArray(this.products ?? [], event.previousIndex, event.currentIndex);
  }

  onBlur() {
    if (this.router.url.match('^/product/edit')) {
      this.editProductNameCheck();
    } else {
      this.createProductNameCheck();
    }
  }

  createProductNameCheck() {
    if (this.router.url.match('^/create/product') && this.products) {
      this.products.forEach((current) => {
        if (this.productForm.value.productName === '') {
          this.productForm.value.productName = 'New Product';
        }
        if (current.id === '-1') {
          current.productName = this.productForm.value.productName as string;
        }
      });
    }
  }

  setNewProduct() {
    this.arraySettled = false;
    this.subscriptions.add(
      this.productService.$products.subscribe((res) => {
        this.products = res
          .filter(
            (product) =>
              product.categoryId === (this.router.url.match('^/product/view') ? this.product.categoryId : this.productForm.get('categoryId')?.value),
          )
          .sort((a, b) => a.orderOfDisplay - b.orderOfDisplay);
        this.checkRoute(this.products);

        if (this.router.url.match('^/create/product') && this.products && !this.products.some((product) => product.id === '-1')) {
          const Product = {
            ...this.product,
            id: '-1',
            productName: 'New Product',
            orderOfDisplay: this.products.length - 1,
            categoryId: this.productForm.get('categoryId')?.value ?? '',
          };
          this.products.push(Product);
          this.createProductNameCheck();
        } else if (
          this.product != null &&
          this.router.url.match('^/product/edit') &&
          !this.products.some((product) => product.id === this.product.id)
        ) {
          this.products.push(this.product);
        }
        this.arraySettled = true;
      }),
    );
  }

  editProductNameCheck() {
    if (this.products) {
      this.products.forEach((current) => {
        if (this.productForm.value.productName === '') {
          this.productForm.value.productName = 'New Product';
        }
        if (current.id === this.product.id) {
          current.productName = this.productForm.value.productName as string;
        }
      });
    }
  }

  highlightProductCheck() {
    this.selectedProduct = this.product.id;
  }

  fixIcon() {
    if (this.productForm.value.icon) {
      this.productForm.get('icon')?.patchValue(JSON.parse(String(this.productForm.value.icon)));
    }
  }

  selectedIcon(icon: any) {
    try {
      return this.product.icon.data === icon.data && this.product.icon.type === icon.type;
    } catch (error) {
      return false;
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.productForm.dirty ? this.confirmModal() : (this.hasUnsavedChanges = false);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: any) {
    if (this.productForm.dirty) {
      event.preventDefault();
    }
  }

  confirmModal() {
    const userChoice = confirm('Are you sure you want to leave? Changes made may not be saved.');
    userChoice ? (this.hasUnsavedChanges = false) : this.hasUnsavedChanges;
  }

  urlPath(path: string) {
    this.navPath = path;
  }

  turnOffRouteGuard() {
    this.hasUnsavedChanges = false;
    this.router.navigateByUrl(`/${this.navPath}/view`, { state: this.navObject.object });
  }

  resetModal() {
    this.openNavModal = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
