import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultUrlSerializer, Router } from '@angular/router';
import { PerkService } from '@services/perk.service';
import { RecommendedService } from '@services/recommended.service';
import { RevisionService } from '@services/revision.service';
import { IIQ_LINK, POLICIES_REQUIRED, RATED_STATES, RATED_STATES_SAVE, TENURE_VALUES } from '@shared/constants';
import { Category, Icon, Perk, Product, RecommendedTile, Revision, Rule } from '@shared/types';
import {
  defaultMaxDate,
  effectiveDateMatchValidator,
  effectiveDateValidator,
  imageUploadValidator,
  perkFormValueValidator,
  requiredIfFactory,
  revisionValidator,
  rolloutPercentageValidator,
  shouldDisplayError,
} from '@shared/validators';
import { Buffer } from 'buffer';
import { Observable, Subscription, of } from 'rxjs';

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss'],

  encapsulation: ViewEncapsulation.None,
})
export class RecommendedTileComponent implements OnInit, OnDestroy {
  urlSerializer = new DefaultUrlSerializer();
  recommendedTiles: RecommendedTile[] = [] as RecommendedTile[];
  buttonBar = true;
  isCreate = false;
  readOnly = true;
  isEdit = false;
  openModal = false;
  openRequestErrorModal = false;
  link = IIQ_LINK;
  policiesRequired = POLICIES_REQUIRED;
  ratedStates = RATED_STATES;
  statesSave = RATED_STATES_SAVE;
  tenureArray = TENURE_VALUES;
  recommendedTile: RecommendedTile = {} as RecommendedTile;
  perks: Perk[] | undefined = undefined;
  perk?: Perk;

  matchStates: string[] = [];
  rules: Rule[] = [] as Rule[];
  savedStates: string[] = [];
  currentIndex = 0;
  selectedRecommendedTile = '';
  images = [] as Icon[];
  imageUrl = '';
  showLoadingPreview = false;
  revisions: Observable<Revision[]> = of([]);
  maxDate: string | null = defaultMaxDate;
  shouldDisplayError = shouldDisplayError;
  private subscriptions: Subscription = new Subscription();
  hasUnsavedChanges = true;
  openNavModal = false;
  selectedFile?: File;
  showImageError = false;
  showImageExists = false;
  navObject = { object: {} };
  navPath = '';

  constructor(
    private router: Router,
    private location: Location,
    private fb: FormBuilder,
    private revisionService: RevisionService,
    private recommendedService: RecommendedService,
    private perkService: PerkService,
  ) {
    const createCheck = this.router.url.match('^/create/*') != null;
    this.buttonBar = createCheck;
    this.isCreate = createCheck;
  }

  recommendedTileForm = this.fb.group({
    perkFormValue: new FormControl('', perkFormValueValidator),
    recommendedTileName: new FormControl(''),
    analyticsName: new FormControl(''),
    description: new FormControl('', requiredIfFactory(this.descriptionRequiredCondition.bind(this), 'This field is required if there is no URL ')),
    image: new FormControl<{ type: string; data: string } | null>(null),
    mobileUrl: new FormControl(''),
    webUrl: new FormControl(''),
    ecnGroup: new FormGroup({
      ecnEnabled: new FormControl(false),
    }),
    enabled: new FormControl(true, Validators.required),
    effectiveDate: new FormControl('', [effectiveDateValidator]),
    rolloutPercentage: new FormControl<number | string>('', rolloutPercentageValidator),
    locationGroup: new FormGroup({
      displayInMobile: new FormControl(false),
      displayInWeb: new FormControl(false),
    }),
  });

  descriptionRequiredCondition(): boolean | null {
    return (
      (this.recommendedTileForm.controls.locationGroup.controls.displayInMobile.value && !this.recommendedTileForm.controls.mobileUrl.value) ||
      (this.recommendedTileForm.controls.locationGroup.controls.displayInWeb.value && !this.recommendedTileForm.controls.webUrl.value) ||
      false
    );
  }

  toggleCheckbox(control: FormControl) {
    control.patchValue(!control.value);
    control.markAsTouched();
    this.openModal = false;
  }

  modalCancelToggle() {
    this.recommendedTileForm.controls.enabled.patchValue(true);
    this.openModal = false;
  }

  openStatusModal(event: MouseEvent) {
    if (this.recommendedTileForm.controls['enabled'].value) {
      event.preventDefault();
      this.openModal = true;
    }
  }

  validateControl(control: AbstractControl) {
    control.markAsDirty();
    control.markAllAsTouched();
    control.updateValueAndValidity();
  }

  retrieveImage(fileName: string) {
    this.showLoadingPreview = true;
    this.subscriptions.add(
      this.recommendedService.getImage(fileName).subscribe((image) => {
        this.imageUrl = image.url;
        this.showLoadingPreview = false;
      }),
    );
  }

  fixImage() {
    if (this.recommendedTileForm.value.image) {
      this.recommendedTileForm.controls.image.patchValue(JSON.parse(String(this.recommendedTileForm.value.image)));
      this.retrieveImage(this.recommendedTileForm.value.image.data);
    }
  }

  selectedImage(image: any) {
    try {
      return this.recommendedTile.image.data === image.data && this.recommendedTile.image.type === image.type;
    } catch (error) {
      return false;
    }
  }

  revisionForm = new FormGroup({
    revisionComment: new FormControl('', revisionValidator),
  });

  async edit(editObject: { active: boolean; object: Category | Product | Perk | RecommendedTile }) {
    const recommendedTile = this.recommendedTile ? this.recommendedTile : (editObject.object as RecommendedTile);
    if (editObject.active) {
      this.router.navigateByUrl('/recommended/edit', { state: recommendedTile });
    }
  }

  async nav(navObject: { object: Category | Product | Perk | RecommendedTile }) {
    if ((this.router.url.match('^/recommended/edit') || this.isCreate) && this.recommendedTileForm.touched) {
      this.openNavModal = true;
      this.navObject.object = navObject.object;
    } else {
      this.hasUnsavedChanges = false;
      this.recommendedTile = navObject.object as RecommendedTile;
      this.retrieveImage(this.recommendedTile.image?.data);
      this.recommendedService.$recommendedTiles.subscribe((res) => {
        this.recommendedTiles = res;
      });
      if (this.recommendedTile.id && this.readOnly) this.revisions = this.revisionService.getRevisions('recommendedTiles', this.recommendedTile.id);
      this.highlightRecommendedCheck();
    }
  }

  async save(activated: boolean) {
    const { locationGroup, ecnGroup, ...recommendedTileForm } = this.recommendedTileForm.value;
    const formRecommendedTile = {
      ...this.recommendedTile,
      ...recommendedTileForm,
      ...locationGroup,
      ...ecnGroup,
    };
    this.recommendedTile = formRecommendedTile as RecommendedTile;
    [...Object.values(this.recommendedTileForm.controls), ...Object.values(this.revisionForm.controls)].forEach(this.validateControl);
    if (activated) {
      if (this.recommendedTileForm.valid && (this.revisionForm.valid || this.isCreate)) {
        (window as any).requestIndicator.show();
        const revisionComment = this.revisionForm.value.revisionComment as string;
        this.subscriptions.add(
          this.recommendedService.saveRecommendedTiles(this.recommendedTile, revisionComment).subscribe({
            next: (recommendedTile) => {
              this.recommendedTile = recommendedTile;
              this.buttonBar = false;
              this.hasUnsavedChanges = false;
              this.highlightRecommendedCheck();
              (window as any).requestIndicator.hide();
              this.router.navigateByUrl('/recommended/view', { state: this.recommendedTile });
            },
            error: () => {
              (window as any).requestIndicator.hide();
              this.openRequestErrorModal = true;
            },
          }),
        );
      }
    }
  }

  async delete(activated: boolean) {
    if (activated) {
      (window as any).requestIndicator.show();
      this.subscriptions.add(
        this.recommendedService.deleteRecommendedTile(this.recommendedTile).subscribe({
          next: () => {
            (window as any).requestIndicator.hide();
            this.router.navigateByUrl(`/home`);
          },
          error: () => {
            (window as any).requestIndicator.hide();
            this.openRequestErrorModal = true;
          },
        }),
      );
    }
  }

  ngOnInit() {
    this.perkService.$perks.subscribe((res) => {
      this.perks = res;
    });
    this.subscriptions.add(
      this.recommendedService.$recommendedTiles.subscribe((res) => {
        this.recommendedTiles = res;
      }),
    );
    this.subscriptions.add(
      this.recommendedService.getImages().subscribe((images) => {
        const formattedImages = images.map((image) => ({ type: 'url', data: image }));
        this.images = formattedImages;
      }),
    );
    this.readOnly = sessionStorage.getItem('readOnly') === 'false' ? false : true;
    this.recommendedTile = this.location.getState() as RecommendedTile;
    if (this.recommendedTile.image?.data) this.retrieveImage(this.recommendedTile.image?.data);
    this.highlightRecommendedCheck();
    if (this.recommendedTile.id && this.readOnly) this.revisions = this.revisionService.getRevisions('recommendedTiles', this.recommendedTile.id);
    this.editCheck();
    this.readOnly = sessionStorage.getItem('readOnly') === 'false' ? false : true;
  }

  editCheck() {
    if (this.recommendedTile != null && this.router.url.match('^/recommended/edit')) {
      this.isEdit = true;
      this.buttonBar = true;

      setTimeout(() => {
        this.recommendedTileForm.patchValue({
          ...this.recommendedTileForm.value,
          ...this.recommendedTile,
          locationGroup: {
            displayInMobile: this.recommendedTile.displayInMobile || false,
            displayInWeb: this.recommendedTile.displayInWeb || false,
          },
          ecnGroup: {
            ecnEnabled: this.recommendedTile.ecnEnabled || false,
          },
        });
      }, 700);
    } else {
      // prevent checkbox toggling animation on edit page load
      // by defaulting to false and then setting to true if not edit mode
      setTimeout(() => this.recommendedTileForm.controls.enabled.setValue(true));
    }
  }

  createRecommendedTileNameCheck() {
    if (this.router.url.match('^/create/recommended')) {
      this.recommendedTiles.forEach((current) => {
        if (this.recommendedTileForm.value.recommendedTileName === '') {
          current.recommendedTileName = 'New Recommended Tile';
        }
        if (current.id === '-1') {
          current.recommendedTileName = this.recommendedTileForm.value.recommendedTileName as string;
        }
      });
    }
  }

  setRecommendedFormValues() {
    if (this.perks) {
      this.perk = this.perks.find((perk) => perk.id === this.recommendedTileForm.value.perkFormValue);
    }

    this.recommendedTileForm.patchValue({
      recommendedTileName: this.perk?.perkName || null,
      description: this.perk?.description || null,
      analyticsName: this.perk?.analyticsName || null,
      rolloutPercentage: this.perk?.rolloutPercentage || null,
      locationGroup: {
        displayInMobile: this.perk?.displayInMobile || false,
        displayInWeb: this.perk?.displayInWeb || false,
      },
      mobileUrl: this.perk?.mobileUrl || null,
      webUrl: this.perk?.webUrl || null,
      ecnGroup: {
        ecnEnabled: this.perk?.ecnEnabled || false,
      },
    });

    const perkFormRules = this.perk?.rules;
    if (perkFormRules) {
      this.recommendedTile.rules = perkFormRules;
    }
    this.recommendedTileForm.controls.effectiveDate?.patchValue(null);
    this.recommendedTileForm.get('effectiveDate')?.addValidators(effectiveDateMatchValidator(this.perk?.effectiveDate) ?? null);

    // this.recommendedTileForm.get('effectiveDate')?.addValidators(effectiveDateMatchValidator(this.perk?.effectiveDate) ?? null);
    // this.recommendedTileForm.get('effectiveDate')?.updateValueAndValidity();
  }

  onFileSelected(event?: Event) {
    /*
     * Typescript has no clue about the "files" property on the event target when the input type
     * is of type "file".  This leaves us no choice but to ignore the check.  Isn't angular wonderful?
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const file = event?.target?.files[0];

    if (!file) {
      console.log('No file selected, assuming user cancelled.');
      return;
    }

    this.selectedFile = file;

    if (this.images.some((f) => f.data === file.name)) {
      this.showImageExists = true;
      return;
    }

    this.uploadImage();
  }

  uploadImage() {
    if (!this.selectedFile) {
      console.warn('No file selected, aborting');
      return;
    }

    // save to local variable to prevent a bunch of (ts-forbidden) null assertions that the above if-statment isn't good
    // enough to prevent apparently.
    const selectedFile = this.selectedFile;

    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result as string;

      image.onload = async () => {
        const validImage = imageUploadValidator(selectedFile, image.naturalWidth, image.naturalHeight);

        if (!validImage.valid) {
          this.showImageError = true;
          return;
        }

        try {
          (window as any).requestIndicator.show();
          const response = await this.recommendedService.getImageStorageUrl(selectedFile);
          await this.recommendedService.saveImage({
            contentType: selectedFile.type,
            buffer: Buffer.from(image.src.split(';base64,')[1], 'base64').buffer,
            url: response.url,
          });

          const newImage = { type: 'url', data: selectedFile.name };

          this.recommendedTileForm.controls.image.patchValue(newImage);
          this.recommendedTile.image = newImage;

          if (!this.images.find((f) => f.data === selectedFile.name)) {
            this.images.push(newImage);
          } else {
            const temp = this.images.filter((f) => f.data !== selectedFile.name);
            temp.push(newImage);
            this.images = temp;
          }

          this.retrieveImage(selectedFile.name);
          (window as any).requestIndicator.hide();
        } catch (error) {
          (window as any).requestIndicator.hide();
          console.error('Error saving image:', error);
          this.openRequestErrorModal = true;
        }
      };
    };
  }

  resetSelectedFile() {
    this.selectedFile = undefined;
  }

  highlightRecommendedCheck() {
    this.selectedRecommendedTile = this.recommendedTile.id;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.recommendedTileForm.dirty ? this.confirmModal() : (this.hasUnsavedChanges = false);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: any) {
    if (this.recommendedTileForm.dirty) {
      event.preventDefault();
    }
  }

  confirmModal() {
    const userChoice = confirm('Are you sure you want to leave? Changes made may not be saved.');
    userChoice ? (this.hasUnsavedChanges = false) : this.hasUnsavedChanges;
  }

  urlPath(path: string) {
    this.navPath = path;
  }

  turnOffRouteGuard() {
    this.hasUnsavedChanges = false;
    this.router.navigateByUrl(`/${this.navPath}/view`, { state: this.navObject.object });
  }

  resetModal() {
    this.openNavModal = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
