<bolt-waiting-indicator id="requestIndicator" fullscreen starthidden></bolt-waiting-indicator>
<div class="bolt-container-fluid" style="padding-bottom: 50px">
  <div class="bolt-row">
    <div class="bolt-col-sm-3">
      <app-nav (nav)="nav($event)" (urlPath)="urlPath($event)" [recommendedTile]="recommendedTile"></app-nav>
    </div>
    <div class="bolt-col">
      <div *ngIf="perks">
        <div *ngIf="!readOnly; else displayAlert">
          <app-button-bar
            [buttonBar]="buttonBar"
            [recommendedTile]="recommendedTile"
            (saved)="save($event)"
            (edit)="edit($event)"
            (delete)="delete($event)"
          ></app-button-bar>
        </div>

        <ng-template #displayAlert>
          <bolt-button-bar class="bolt-background-white">
            <bolt-button slot="back" type="tertiary" iconleft="clock" onclick="window.recommendedTileRevisionModal.openModal()"
              >View Revision History</bolt-button
            >
          </bolt-button-bar>
          <bolt-notification type="info"
            >You can only view the details. To make changes, request the owner for edit access in <a [href]="link">IIQ</a></bolt-notification
          >
        </ng-template>

        <div class="container" *ngIf="buttonBar; else viewField" [formGroup]="recommendedTileForm">
          <h3 class="bolt-heading-sm">Details</h3>
          <div>
            <div class="bolt-body-copy-lg"><strong>Perk</strong><br /></div>
            <bolt-select
              class="perk-dropdown"
              style="margin-bottom: 10px"
              (change)="setRecommendedFormValues()"
              placeholder="Select a perk"
              required
              ngDefaultControl
              [formControl]="recommendedTileForm.controls.perkId"
              [error]="recommendedTileForm.controls.perkId.errors?.['message'] || ''"
            >
              <option *ngFor="let perk of perks" [value]="perk.id">
                {{ perk.perkName }}
              </option>
            </bolt-select>
          </div>
          <div class="bolt-body-copy-lg flex-row">
            <strong>Name</strong>
            <bolt-contextual-help slot="help" type="push" style="margin-left: 10px"> Recommended Tile Name: Max 100 characters </bolt-contextual-help>
          </div>
          <div>
            <bolt-textfield
              inputsize="100"
              class="bolt-space-bottom-lg"
              maxLength="100"
              [error]="shouldDisplayError(recommendedTileForm.controls.recommendedTileName, 'required') ? 'Please enter a name.' : ''"
              required
              ngDefaultControl
              formControlName="recommendedTileName"
            >
            </bolt-textfield>
          </div>
          <div class="bolt-body-copy-lg"><strong>Description</strong><br /></div>
          <p *ngIf="perk?.description">
            {{ perk?.description }}
          </p>
          <div *ngIf="!perk?.description">
            <bolt-textfield
              inputsize="1000"
              class="bolt-space-bottom-lg"
              maxLength="1000"
              [error]="recommendedTileForm.controls.description.errors?.['message'] || ''"
              ngDefaultControl
              formControlName="description"
              value="{{ recommendedTileForm.value.description }}"
            >
            </bolt-textfield>
          </div>
          <div class="bolt-body-copy-lg"><strong>Analytics Name</strong><br /></div>
          <p>{{ perk?.analyticsName }}</p>
          <div class="bolt-body-copy-lg"><strong>Image</strong><br /></div>
          <div ngDefaultControl formControlName="image">
            <div>
              <bolt-select width="fit-content" class="bolt-space-bottom-lg" (change)="fixImage()">
                <option
                  *ngFor="let image of images"
                  value="{ &quot;type&quot;: &quot;{{ image.type }}&quot;, &quot;data&quot;: &quot;{{ image.data }}&quot; }"
                  [selected]="selectedImage(image)"
                >
                  {{ image.data }}
                </option>
              </bolt-select>
              <div *ngIf="recommendedTileForm.value.image?.data && !showLoadingPreview">
                <em>Image Preview</em><br />
                <img [src]="imageUrl" alt="{{ recommendedTileForm.value.image?.data ?? 'S3 Image' }}" width="300" height="194" />
              </div>
              <div *ngIf="showLoadingPreview"><em>Loading image preview...</em></div>
            </div>
          </div>
          <div [hidden]="true">
            <input type="file" accept="image/svg+xml" (change)="onFileSelected($event)" id="uploadFileBtn" />
          </div>
          <div>
            <bolt-button type="primary" class="actionBtn" onclick="uploadFileBtn.click()">Upload photo</bolt-button>
          </div>

          <div class="bolt-row bolt-align-items-center">
            <div class="bolt-col-1">
              <h3 class="bolt-heading-sm" style="margin-right: 20px">Status</h3>
            </div>
            <div class="bolt-col bolt-offset-1">
              <bolt-checkbox-group>
                <div>
                  <bolt-checkbox
                    ngDefaultControl
                    (click)="openStatusModal($event)"
                    (change)="toggleCheckbox(recommendedTileForm.controls.enabled)"
                    [formControl]="recommendedTileForm.controls.enabled"
                    [value]="recommendedTileForm.controls.enabled.value"
                    [checked]="recommendedTileForm.controls.enabled.value"
                    required
                    >{{ recommendedTileForm.controls.enabled.value ? 'On' : 'Off' }}</bolt-checkbox
                  >
                </div>
              </bolt-checkbox-group>
            </div>
          </div>
          <div class="bolt-body-copy-lg"><strong>Effective Date</strong><br /></div>
          <bolt-date-picker
            required
            ngDefaultControl
            formControlName="effectiveDate"
            inputsize="20"
            min="today"
            max="{{ maxDate }}"
            [error]="recommendedTileForm.controls.effectiveDate.errors?.['message'] || ''"
            class="bolt-space-bottom-lg"
            pickerposition="right"
            instructionaltext="The date must not be in the past or more than one year in the future."
          >
          </bolt-date-picker>
          <div class="bolt-body-copy-lg"><strong>Rollout %</strong><br /></div>
          <p>{{ perk?.rolloutPercentage }}</p>
          <hr />
          <h3 class="bolt-heading-sm">Display Info</h3>
          <div class="bolt-body-copy-lg flex-row">
            <strong>Location</strong><br />
            <bolt-contextual-help slot="help" type="push" heading="Notice" style="margin-left: 10px">
              Selection will display URL Field for either Mobile or Website
            </bolt-contextual-help>
          </div>
          <bolt-checkbox-group class="bolt-space-bottom-2xl" required>
            <div class="flex-row">
              <div class="checkbox-container">
                <bolt-checkbox
                  ngDefaultControl
                  disabled
                  name="Mobile"
                  [value]="recommendedTileForm.controls.locationGroup.controls.displayInMobile.value"
                  [checked]="perk?.displayInMobile"
                ></bolt-checkbox>
                <p>Mobile App</p>
              </div>

              <div class="checkbox-container">
                <bolt-checkbox
                  disabled
                  ngDefaultControl
                  name="Web"
                  [value]="recommendedTileForm.controls.locationGroup.controls.displayInWeb.value"
                  [checked]="perk?.displayInWeb"
                ></bolt-checkbox>
                <p>Website</p>
              </div>
            </div>
          </bolt-checkbox-group>
          <div>
            <div *ngIf="this.recommendedTileForm.controls.locationGroup.controls.displayInMobile.value">
              <div class="bolt-body-copy-lg"><strong>Mobile URL</strong><br /></div>
              <p>{{ perk?.mobileUrl }}</p>
            </div>
            <div *ngIf="this.recommendedTileForm.controls.locationGroup.controls.displayInWeb.value">
              <div>
                <div class="bolt-body-copy-lg"><strong>Web URL</strong><br /></div>
                <p>{{ perk?.webUrl }}</p>
              </div>
            </div>
          </div>
          <div class="bolt-body-copy-lg"><strong>ECN</strong><br /></div>
          <p>{{ perk?.ecnEnabled }}</p>
          <hr />
          <div *ngIf="!perk?.rules">
            <h3 class="bolt-heading-sm">Rule</h3>
            <div class="bolt-body-copy-lg"><strong>Policies Required</strong><br /></div>
            <div class="bolt-body-copy-lg"><strong>Tenure of policy needed</strong><br /></div>
            <div class="bolt-body-copy-lg"><strong>Rated State(s)</strong><br /></div>
          </div>
          <div *ngFor="let attachedRules of perk?.rules; let index = index">
            <h3 class="bolt-heading-sm">Rule {{ index + 1 }}</h3>
            <div class="bolt-body-copy-lg"><strong>Policies Required</strong><br /></div>
            <div *ngFor="let attachedPolicies of attachedRules.requiredPolicies">
              <div *ngFor="let policies of attachedPolicies | keyvalue">
                <p>&#8226; {{ policies.key }}: {{ policies.value }}</p>
              </div>
            </div>
            <div class="bolt-body-copy-lg"><strong>Tenure of policy needed</strong><br /></div>
            <p>{{ attachedRules.tenure.display }}</p>
            <div class="bolt-body-copy-lg"><strong>Home Age</strong><br /></div>
            <p>{{ attachedRules.homeAge?.title ?? 'None' }}</p>
            <div class="bolt-body-copy-lg"><strong>Rated State(s)</strong><br /></div>
            <span class="bolt-space-bottom-lg" *ngFor="let attachedStates of attachedRules.ratedState"> &#8226; {{ attachedStates }} </span>
            <hr />
          </div>
          <div *ngIf="!isCreate" [formGroup]="revisionForm">
            <div>
              <bolt-textarea
                inputsize="100"
                class="bolt-space-bottom-lg"
                label="Revision comments"
                maxLength="100"
                required
                [error]="this.revisionForm.controls.revisionComment.errors?.['message'] || ''"
                ngDefaultControl
                formControlName="revisionComment"
                cols="40"
              ></bolt-textarea>
              <p>Enter between 15 and 100 characters</p>
            </div>
          </div>
        </div>

        <ng-template #viewField>
          <h3 class="bolt-heading-sm">Details</h3>
          <div class="bolt-body-copy-lg"><strong>Name</strong><br /></div>
          <p>{{ recommendedTile.recommendedTileName }}</p>
          <div class="bolt-body-copy-lg"><strong>Description</strong><br /></div>
          <p>{{ recommendedTile.description }}</p>
          <div class="bolt-body-copy-lg"><strong>Analytics Name</strong><br /></div>
          <p>{{ recommendedTile.analyticsName }}</p>
          <div class="bolt-body-copy-lg"><strong>Image</strong><br /></div>
          <ng-container *ngIf="recommendedTile.image?.data">
            <img [src]="imageUrl" alt="{{ recommendedTile.image.data }}" width="300" height="194" />
            <br />
            <a href="{{ imageUrl }}" target="_blank">{{ recommendedTile.image.data }}</a>
          </ng-container>
          <hr />
          <div class="bolt-row bolt-align-items-center">
            <div class="bolt-col-1">
              <h3 class="bolt-heading-sm" style="margin-right: 20px">Status</h3>
            </div>
            <div class="bolt-col bolt-offset-1">
              <bolt-checkbox-group>
                <div *ngIf="recommendedTile.enabled; else off">
                  <bolt-checkbox checked="true" disabled>On</bolt-checkbox>
                </div>
                <ng-template #off>
                  <bolt-checkbox disabled>Off</bolt-checkbox>
                </ng-template>
              </bolt-checkbox-group>
            </div>
          </div>
          <div class="bolt-body-copy-lg"><strong>Effective Date</strong><br /></div>
          <p>{{ recommendedTile.effectiveDate }}</p>
          <div class="bolt-body-copy-lg"><strong>Rollout %</strong><br /></div>
          <p>{{ recommendedTile.rolloutPercentage }}</p>
          <hr />

          <h3 class="bolt-heading-sm">Display Info</h3>
          <div class="bolt-body-copy-lg"><strong>Location</strong><br /></div>
          <div class="bolt-row">
            <div class="bolt-col-3" *ngIf="recommendedTile.displayInMobile">
              <p>Mobile App</p>
            </div>
            <div *ngIf="recommendedTile.displayInWeb" class="bolt-col" [class.bolt-col-3]="!recommendedTile.displayInMobile">
              <p>Website</p>
            </div>
          </div>
          <div *ngIf="recommendedTile.mobileUrl && recommendedTile.displayInMobile">
            <div class="bolt-body-copy-lg"><strong>Mobile URL</strong><br /></div>
            <p>{{ recommendedTile.mobileUrl }}</p>
          </div>
          <div *ngIf="recommendedTile.webUrl && recommendedTile.displayInWeb">
            <div class="bolt-body-copy-lg"><strong>Web URL</strong><br /></div>
            <p>{{ recommendedTile.webUrl }}</p>
          </div>
          <div class="bolt-body-copy-lg"><strong>ECN</strong><br /></div>
          <div *ngIf="recommendedTile.ecnEnabled; else ecnDisabled">
            <p>Included</p>
          </div>
          <ng-template #ecnDisabled>
            <p>Not Included</p>
          </ng-template>
          <hr />
          <div *ngFor="let attachedRules of recommendedTile.rules; let index = index">
            <h3 class="bolt-heading-sm">Rule {{ index + 1 }}</h3>
            <div class="bolt-body-copy-lg"><strong>Policies Required</strong><br /></div>
            <div *ngFor="let attachedPolicies of recommendedTile.rules[index].requiredPolicies">
              <div *ngFor="let policies of attachedPolicies | keyvalue">
                <p>&#8226; {{ policies.key }}: {{ policies.value }}</p>
              </div>
            </div>
            <div class="bolt-body-copy-lg"><strong>Tenure of policy needed</strong><br /></div>
            <p>{{ recommendedTile.rules[index].tenure.display }}</p>
            <div class="bolt-body-copy-lg"><strong>Home Age</strong><br /></div>
            <p>{{ recommendedTile.rules[index].homeAge?.title ?? 'None' }}</p>
            <div class="bolt-body-copy-lg"><strong>Rated State(s)</strong><br /></div>
            <span class="bolt-space-bottom-lg" *ngFor="let attachedStates of recommendedTile.rules[index].ratedState">
              &#8226; {{ attachedStates }}
            </span>
            <hr />
          </div>
        </ng-template>
      </div>
      <div *ngIf="!perks">
        <bolt-waiting-indicator>Loading...</bolt-waiting-indicator>
      </div>
    </div>
  </div>

  <!-- Revision Modal -->
  <bolt-modal id="recommendedTileRevisionModal" type="content" heading="Revision history">
    <app-revision-table [revisions]="(revisions | async) || []"></app-revision-table>
    <bolt-button-bar slot="modal-button-bar">
      <bolt-button type="primary" slot="forward" onclick="window.recommendedTileRevisionModal.closeModal()">Dismiss</bolt-button>
    </bolt-button-bar>
  </bolt-modal>

  <bolt-modal id="customNavButtonModal" type="question" heading="Are you sure you want to leave?" [open]="this.openNavModal">
    <p>Unsaved changes may be lost.</p>

    <bolt-button-bar slot="modal-button-bar">
      <bolt-button slot="back" (click)="turnOffRouteGuard()" onclick="window.customNavButtonModal.closeModal()">Navigate</bolt-button>
      <bolt-button slot="cancel" (click)="resetModal()" onclick="window.customNavButtonModal.closeModal()">Cancel</bolt-button>
    </bolt-button-bar>
  </bolt-modal>

  <!-- Duplicate image error Modal -->
  <bolt-modal
    id="duplicateImageErrorModal"
    type="question"
    heading="Image already exists"
    [open]="this.showImageExists"
    primarybutton="Override"
    secondarybutton="Cancel"
    (bolt-modal-secondary-button-click)="resetSelectedFile()"
    (bolt-modal-primary-button-click)="uploadImage()"
  >
    <p>An image with this name already exists, do you wish to override it?</p>
  </bolt-modal>

  <!-- Image Upload error Modal -->
  <bolt-modal id="invalidImageModal" heading="Invalid Image" [open]="this.showImageError">
    <p>Please upload a valid image.</p>

    <bolt-button-bar slot="modal-button-bar">
      <bolt-button slot="back" (click)="resetSelectedFile()" onclick="window.invalidImageModal.closeModal()">Back</bolt-button>
    </bolt-button-bar>
  </bolt-modal>

  <!-- Confirmation Modal -->
  <bolt-modal
    id="questionModal"
    type="question"
    heading="Turn off Recommended Tile?"
    (bolt-modal-secondary-button-click)="toggleCheckbox(recommendedTileForm.controls.enabled)"
    (bolt-modal-primary-button-click)="modalCancelToggle()"
    primarybutton="No, keep on"
    secondarybutton="Yes, turn off"
    [open]="this.openModal"
  >
    <p>This recommended tile will no longer be shown to users</p>
  </bolt-modal>
</div>

<!-- Request Error Modal -->
<bolt-modal
  id="requestErrorModal"
  type="error"
  heading="Error"
  primarybutton="Close"
  [open]="openRequestErrorModal"
  (bolt-modal-primary-button-click)="openRequestErrorModal = false"
>
  <p>There was an issue processing your request.</p>
</bolt-modal>
