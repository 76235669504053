import { Component, OnDestroy, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/shared/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'trove-admin-portal';
  private subscriptions: Subscription = new Subscription();

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private readonly config: ConfigService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.oidcSecurityService.checkAuth().subscribe(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ({ isAuthenticated, userData, accessToken, idToken, configId }) => {
          if (userData?.groups.includes(this.config.app.secrets.ldap)) {
            sessionStorage.setItem('readOnly', 'false');
          }
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
