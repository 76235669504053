import { Icon } from './icon';
import { Rule } from './rule';

export interface RecommendedTile {
  id: string;
  created: number;
  lastUpdated: number;
  navigationId: number;
  recommendedTileName: string;
  analyticsName: string;
  header: string;
  description: string;
  image: Icon;
  mobileUrl: string;
  webUrl: string;
  ecnEnabled: boolean;
  enabled: boolean;
  effectiveDate: string;
  rolloutPercentage: string;
  displayInMobile: boolean;
  displayInWeb: boolean;
  productId: string;
  rules: Rule[];
  userName: string;
  userId: string;
  revisionComment?: string;
}
