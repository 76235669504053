import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, switchMap, throwError } from 'rxjs';
import { v4 } from 'uuid';
import { ConfigService } from '../shared/config.service';
import { Revision } from '../shared/types/revision';

@Injectable({
  providedIn: 'root',
})
export class RevisionService {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
    public oidcSecurityService: OidcSecurityService,
  ) {}

  clientId = this.config.app.secrets.clientId;
  messageId = () => v4();
  public url = this.config.app.secrets.url;

  getRevisions(type: string, typeId: string): Observable<Revision[]> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.get<Revision[]>(this.url + `/revisions?type=${type}&typeId=${typeId}`, {
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }
}
