<bolt-waiting-indicator id="requestIndicator" fullscreen starthidden></bolt-waiting-indicator>
<div class="bolt-container-fluid" style="padding-bottom: 50px">
  <div class="bolt-row">
    <div class="bolt-col-sm-3">
      <app-nav (nav)="nav($event)" (urlPath)="urlPath($event)"></app-nav>
    </div>
    <div class="bolt-col">
      <div *ngIf="categories">
        <div *ngIf="!readOnly; else displayAlert">
          <app-button-bar
            [buttonBar]="buttonBar"
            [category]="category"
            (saved)="save($event)"
            (edit)="edit($event)"
            (delete)="delete($event)"
          ></app-button-bar>
        </div>

        <ng-template #displayAlert>
          <bolt-button-bar class="bolt-background-white">
            <bolt-button slot="back" type="tertiary" iconleft="clock" onclick="window.categoryRevisionModal.openModal()"
              >View Revision History</bolt-button
            >
          </bolt-button-bar>
          <bolt-notification type="info"
            >You can only view the details. To make changes, request the owner for edit access in <a [href]="link">IIQ</a></bolt-notification
          >
        </ng-template>

        <div class="container" *ngIf="buttonBar; else viewField" [formGroup]="categoryForm">
          <h3 class="bolt-heading-sm">Details</h3>
          <div>
            <bolt-textfield
              (blur)="onBlur()"
              inputsize="100"
              class="bolt-space-bottom-lg"
              label="Name"
              required
              maxLength="50"
              [error]="shouldDisplayError(categoryForm.controls.categoryName, 'required') ? 'This field is required' : ''"
              ngDefaultControl
              formControlName="categoryName"
            >
              <bolt-contextual-help slot="help" type="push" heading="Required"> Category Name: Max 50 characters </bolt-contextual-help>
            </bolt-textfield>
          </div>
          <bolt-textfield
            inputsize="125"
            class="bolt-space-bottom-lg"
            label="Subtext"
            maxLength="125"
            ngDefaultControl
            formControlName="subtext"
            value="{{ category.subtext }}"
          >
            <bolt-contextual-help slot="help" type="push" heading="Optional"> Category Subtext: Max 125 characters </bolt-contextual-help>
          </bolt-textfield>
          <div ngDefaultControl formControlName="icon">
            <bolt-select width="fit-content" class="bolt-space-bottom-lg" label="Icon">
              <bolt-contextual-help slot="help" type="push" heading="Optional"> Icon </bolt-contextual-help>
              <option *ngFor="let icon of this.icons" [value]="icon.data" [selected]="selectedIcon(icon)">
                {{ icon.data }}
              </option>
            </bolt-select>
          </div>

          <div class="bolt-row bolt-align-items-center">
            <div class="bolt-col-1">
              <h3 class="bolt-heading-sm" style="margin-right: 20px">Status</h3>
            </div>
            <div class="bolt-col bolt-offset-1">
              <div>
                <bolt-checkbox-group>
                  <bolt-checkbox
                    ngDefaultControl
                    (click)="openStatusModal($event)"
                    [formControl]="categoryForm.controls.enabled"
                    [value]="categoryForm.controls.enabled.value"
                    [checked]="categoryForm.controls.enabled.value"
                    (change)="toggleCheckbox(categoryForm.controls.enabled)"
                    >{{ categoryForm.controls.enabled.value ? 'On' : 'Off' }}</bolt-checkbox
                  >
                </bolt-checkbox-group>
              </div>
            </div>
          </div>

          <div>
            <bolt-date-picker
              required
              ngDefaultControl
              formControlName="effectiveDate"
              inputsize="20"
              label="Effective Date"
              min="today"
              max="{{ maxDate }}"
              [error]="categoryForm.controls.effectiveDate.errors?.['message'] || ''"
              class="bolt-space-bottom-lg"
              pickerposition="right"
              instructionaltext="The date must not be in the past or more than one year in the future"
            >
            </bolt-date-picker>
            <bolt-textfield
              type="number"
              min="0"
              max="100"
              [error]="categoryForm.controls.rolloutPercentage.errors?.['message'] || ''"
              inputsize="5"
              class="bolt-space-bottom-lg"
              suffix="%"
              label="Rollout %"
              optionaltext="hide"
              aligninput="right"
              required
              ngDefaultControl
              formControlName="rolloutPercentage"
            ></bolt-textfield>
          </div>

          <div class="bolt-space-top-lg">
            <h3 class="bolt-heading-sm">Display Info</h3>
            <div>
              <bolt-checkbox-group
                class="bolt-space-bottom-2xl"
                label="Location"
                [error]="categoryForm.controls.locationGroup.errors?.['message'] || ''"
                required
              >
                <bolt-checkbox
                  ngDefaultControl
                  (change)="toggleCheckbox(categoryForm.controls.locationGroup.controls.displayInMobile)"
                  [formControl]="categoryForm.controls.locationGroup.controls.displayInMobile"
                  class="float-child"
                  name="Mobile"
                  [value]="categoryForm.controls.locationGroup.controls.displayInMobile.value"
                  [checked]="categoryForm.controls.locationGroup.controls.displayInMobile.value"
                  >Mobile App</bolt-checkbox
                >
                <bolt-checkbox
                  ngDefaultControl
                  (change)="toggleCheckbox(categoryForm.controls.locationGroup.controls.displayInWeb)"
                  [formControl]="categoryForm.controls.locationGroup.controls.displayInWeb"
                  class="float-child"
                  name="Web"
                  [value]="categoryForm.controls.locationGroup.controls.displayInWeb.value"
                  [checked]="categoryForm.controls.locationGroup.controls.displayInWeb.value"
                  >Website</bolt-checkbox
                >
              </bolt-checkbox-group>
            </div>

            <div>
              <bolt-select
                class="bolt-space-bottom-lg"
                style="margin-bottom: 10px"
                placeholder="Select Mobile Section"
                label="Mobile Section"
                required
                ngDefaultControl
                [formControl]="categoryForm.controls.mobileSection"
                [error]="shouldDisplayError(categoryForm.controls.mobileSection, 'required') ? 'This field is required' : ''"
              >
                <ng-container *ngFor="let mobileSection of mobileSections">
                  <option *ngIf="mobileSection.display" [value]="mobileSection.name">
                    {{ mobileSection.name }}
                  </option>
                </ng-container>
              </bolt-select>
            </div>
            <div *ngIf="categoryForm.controls.mobileSection.value && categoryForm.controls.mobileSection.value !== mobileSectionName.Tile">
              <div class="bolt-body-copy-md"><strong>Order of Display</strong><br /></div>
              <div cdkDropList class="drop-list" (cdkDropListDropped)="drop($event)">
                <div class="drop-box" *ngFor="let category of categories" cdkDrag>
                  <div class="inner-box">
                    {{ category.categoryName }}
                    <bolt-icon class="icon" name="menu"></bolt-icon>
                  </div>
                </div>
              </div>
              <p style="font-size: 12px">Drag to reorder.</p>
            </div>
            <div *ngIf="!isCreate" [formGroup]="revisionForm">
              <div>
                <bolt-textarea
                  inputsize="100"
                  class="bolt-space-bottom-lg"
                  label="Revision comments"
                  maxLength="100"
                  required
                  [error]="this.revisionForm.controls.revisionComment.errors?.['message'] || ''"
                  ngDefaultControl
                  formControlName="revisionComment"
                  cols="40"
                ></bolt-textarea>
                <p>Enter between 15 and 100 characters</p>
              </div>
            </div>
          </div>
        </div>

        <ng-template #viewField>
          <h3 class="bolt-heading-sm">Details</h3>
          <div><strong>Name</strong></div>
          <p>{{ category.categoryName }}</p>
          <div><strong>Subtext</strong></div>
          <p>{{ category.subtext }}</p>
          <div><strong>Icon</strong></div>
          <p>{{ category.icon.data || '' }}</p>
          <hr />
          <div class="bolt-row bolt-align-items-center">
            <div class="bolt-col-1">
              <h3 class="bolt-heading-sm" style="margin-right: 20px">Status</h3>
            </div>
            <div class="bolt-col bolt-offset-1">
              <bolt-checkbox-group>
                <div *ngIf="category.enabled; else off">
                  <bolt-checkbox checked="true" disabled>On</bolt-checkbox>
                </div>
                <ng-template #off>
                  <bolt-checkbox disabled>Off</bolt-checkbox>
                </ng-template>
              </bolt-checkbox-group>
            </div>
          </div>

          <div><strong>Effective Date</strong></div>
          <p>{{ category.effectiveDate }}</p>
          <div><strong>Rollout %</strong></div>
          <p>{{ category.rolloutPercentage }}</p>
          <hr />
          <h1 class="bolt-heading-sm">Display Info</h1>
          <div><strong>Location</strong></div>
          <div class="bolt-row">
            <div class="bolt-col-3">
              <p *ngIf="category.displayInMobile">Mobile App</p>
            </div>
            <div class="bolt-col">
              <p *ngIf="category.displayInWeb">Website</p>
            </div>
          </div>
          <div><strong>Mobile Section</strong></div>
          <p>{{ category.mobileSection }}</p>
          <br />

          <div *ngIf="this.category.mobileSection !== mobileSectionName.Tile">
            <div class="bolt-body-copy-lg"><strong>Order of Display</strong><br /></div>
            <ol>
              <li *ngFor="let category of categories" [ngClass]="{ highlighted: category.id === selectedCategory }">
                {{ category.categoryName }}
              </li>
            </ol>
          </div>
        </ng-template>
      </div>
      <div *ngIf="!categories">
        <bolt-waiting-indicator>Loading...</bolt-waiting-indicator>
      </div>
    </div>
  </div>
</div>

<!-- Revision Modal -->
<bolt-modal id="categoryRevisionModal" type="content" heading="Revision history">
  <app-revision-table [revisions]="(revisions | async) || []"></app-revision-table>
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button type="primary" slot="forward" onclick="window.categoryRevisionModal.closeModal()">Dismiss</bolt-button>
  </bolt-button-bar>
</bolt-modal>

<bolt-modal id="customNavButtonModal" type="question" heading="Are you sure you want to leave?" [open]="this.openNavModal">
  <p>Unsaved changes may be lost.</p>

  <bolt-button-bar slot="modal-button-bar">
    <bolt-button slot="back" (click)="turnOffRouteGuard()" onclick="window.customNavButtonModal.closeModal()">Navigate</bolt-button>
    <bolt-button slot="cancel" (click)="resetModal()" onclick="window.customNavButtonModal.closeModal()">Cancel</bolt-button>
  </bolt-button-bar>
</bolt-modal>

<!-- Confirmation Modal -->
<bolt-modal
  id="questionModal"
  type="question"
  heading="Turn off Category?"
  (bolt-modal-secondary-button-click)="toggleCheckbox(categoryForm.controls.enabled)"
  (bolt-modal-primary-button-click)="modalCancelToggle()"
  primarybutton="No, keep on"
  secondarybutton="Yes, turn off"
  [open]="this.openModal"
>
  <p>This category will no longer be shown to users</p>
</bolt-modal>

<!-- Request Error Modal -->
<bolt-modal
  id="requestErrorModal"
  type="error"
  heading="Error"
  primarybutton="Close"
  [open]="openRequestErrorModal"
  (bolt-modal-primary-button-click)="openRequestErrorModal = false"
>
  <p>There was an issue processing your request.</p>
</bolt-modal>
