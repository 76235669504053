import { Icon } from './icon';

export interface Product {
  id: string;
  productName: string;
  subtext: string;
  icon: Icon;
  enabled: boolean;
  effectiveDate: string;
  rolloutPercentage: number;
  displayInMobile: boolean;
  displayInWeb: boolean;
  orderOfDisplay: number;
  userName: string;
  userId: string;
  revisionComment?: string;
}
