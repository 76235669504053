<div class="bolt-table bolt-table-striped bolt-table-dark-blue">
  <table>
    <thead>
      <tr>
        <th id="date" scope="col">
          <div class="headers">Date <bolt-icon class="icon" name="sort-unsorted" (click)="sortDate()"></bolt-icon></div>
        </th>
        <th id="editor" scope="col">
          <div class="headers">Editor <bolt-icon class="icon" name="sort-unsorted" (click)="sortEditor()"></bolt-icon></div>
        </th>
        <th id="comments" scope="col">
          <div class="headers">Comments <bolt-icon class="icon" name="sort-unsorted" (click)="sortComment()"></bolt-icon></div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let revision of revisions">
        <td>{{ revision.created | date: 'shortDate' }}</td>
        <td>{{ revision.userName }}</td>
        <td>{{ revision.revisionComment }}</td>
      </tr>
    </tbody>
  </table>
</div>
