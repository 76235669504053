import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription, map, switchMap, throwError } from 'rxjs';
import { v4 } from 'uuid';
import { ConfigService } from '../shared/config.service';
import { Category } from '../shared/types/categories';

@Injectable({
  providedIn: 'root',
})
export class CategoryService implements OnDestroy {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
    public oidcSecurityService: OidcSecurityService,
  ) {}

  public clientId = this.config.app.secrets.clientId;
  messageId = () => v4();
  public url = this.config.app.secrets.url;
  public $categories = this.getCategories();
  private subscriptions: Subscription = new Subscription();

  saveCategory(category: Category, revisionComment: string): Observable<Category> {
    this.getUserId(category);
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          if (category.id) {
            category.revisionComment = revisionComment;
            return this.http.put<Category>(this.url + `/categories/${category.id}`, category, {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            });
          } else {
            return this.http.post<Category>(this.url + '/categories', category, {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            });
          }
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getCategories(): Observable<Category[]> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http
            .get<Category[]>(this.url + '/categories', {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            })
            .pipe(map((categories) => categories.sort((a, b) => (a.categoryName.toUpperCase() > b.categoryName.toUpperCase() ? 1 : -1))));
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  deleteCategory(category: Category): Observable<Category> {
    this.getUserId(category);
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.delete<Category>(this.url + `/categories/${category.id}`, {
            body: category,
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getUserId(category: Category) {
    this.subscriptions.add(
      this.oidcSecurityService.getUserData().subscribe((userData) => {
        category.userName = userData.displayName;
        category.userId = userData.userId;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
