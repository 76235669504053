import { Component, Input } from '@angular/core';
import { Revision } from '../../shared/types/revision';

@Component({
  selector: 'app-revision-table',
  templateUrl: './revision-table.component.html',
  styleUrls: ['./revision-table.component.scss'],
})
export class RevisionTableComponent {
  @Input() revisions: Revision[] = [];

  dateSort = false;
  editorSort = false;
  commentSort = false;

  sortDate() {
    const value = this.dateSort;
    if (value === false) {
      this.revisions = this.revisions.sort((a, b) => a.lastUpdated - b.lastUpdated);
      this.dateSort = true;
    } else {
      this.revisions = this.revisions.sort((a, b) => b.lastUpdated - a.lastUpdated);
      this.dateSort = false;
    }
  }

  sortEditor() {
    const value = this.editorSort;
    if (value === false) {
      this.revisions = this.revisions.sort((a, b) => (a.userName.toUpperCase() > b.userName.toUpperCase() ? 1 : -1));
      this.editorSort = true;
    } else {
      this.revisions = this.revisions.sort((a, b) => (a.userName.toUpperCase() < b.userName.toUpperCase() ? 1 : -1));
      this.editorSort = false;
    }
  }

  sortComment() {
    const value = this.commentSort;
    if (value === false) {
      this.revisions = this.revisions.sort((a, b) => (a.revisionComment.trim().toUpperCase() > b.revisionComment.trim().toUpperCase() ? 1 : -1));
      this.commentSort = true;
    } else {
      this.revisions = this.revisions.sort((a, b) => (a.revisionComment.trim().toUpperCase() < b.revisionComment.trim().toUpperCase() ? 1 : -1));
      this.commentSort = false;
    }
  }
}
