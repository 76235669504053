<div class="container">
  <bolt-button-bar class="bolt-background-white">
    <ng-container *ngIf="!buttonBar || editView">
      <bolt-button
        color="white"
        type="tertiary"
        iconleft="trash"
        slot="cancel"
        class="bolt-background-cherry-red"
        onclick="window.questionDeleteModal.openModal()"
        >Remove</bolt-button
      >
      <bolt-button slot="alternate" type="tertiary" iconleft="clock" onclick="window.buttonBarRevisionModal.openModal()"
        >View Revision History</bolt-button
      >
    </ng-container>

    <ng-container *ngIf="editView || buttonBar; else displayEdit">
      <bolt-button onclick="window.cancelModal.openModal()" slot="back">Cancel</bolt-button>
      <bolt-button slot="forward" type="primary" (click)="saveFunction(true)">Save</bolt-button>
    </ng-container>

    <ng-template #displayEdit>
      <bolt-button slot="forward" iconleft="pencil" (click)="editFunction(true)">Edit</bolt-button>
    </ng-template>
  </bolt-button-bar>
</div>

<!--Cancel Modal-->
<bolt-modal id="cancelModal" heading="Are you sure you want to cancel?">
  <p>Your content will not be saved</p>
  <bolt-button-bar class="bolt-background-white" slot="modal-button-bar">
    <bolt-button slot="forward" type="primary" onclick="window.cancelModal.closeModal()">No, continue editing</bolt-button>
    <bolt-button slot="back" onclick="window.cancelModal.closeModal()" href="/home">Yes, cancel</bolt-button>
  </bolt-button-bar>
</bolt-modal>

<!-- Remove Modal -->
<bolt-modal id="questionDeleteModal" type="question" heading="Remove {{ type }}?">
  <p>You won't be able to recover this information.</p>
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button
      slot="back"
      type="tertiary"
      color="white"
      class="bolt-background-cherry-red"
      onclick="window.questionDeleteModal.closeModal()"
      (click)="deleteFunction(true)"
      >Yes, remove {{ type }}</bolt-button
    >
    <bolt-button type="primary" slot="forward" onclick="window.questionDeleteModal.closeModal()">No, keep {{ type }}</bolt-button>
  </bolt-button-bar>
</bolt-modal>

<!-- Revision Modal -->
<bolt-modal id="buttonBarRevisionModal" type="content" heading="Revision history">
  <app-revision-table [revisions]="(revisions | async) || []"></app-revision-table>
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button type="primary" slot="forward" onclick="window.buttonBarRevisionModal.closeModal()">Dismiss</bolt-button>
  </bolt-button-bar>
</bolt-modal>
