import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RevisionService } from '@services/revision.service';
import { Category } from '../../shared/types/categories';
import { Perk } from '../../shared/types/perks';
import { Product } from '../../shared/types/products';
import { RecommendedTile } from '../../shared/types/recommendedTile';
import { Revision } from '../../shared/types/revision';

@Component({
  selector: 'app-button-bar',
  templateUrl: './button-bar.component.html',
  styleUrls: ['./button-bar.component.scss'],
})
export class ButtonBarComponent implements OnInit, OnChanges {
  @Input() buttonBar = false;
  @Input() category: Category = {} as Category;
  @Input() product: Product = {} as Product;
  @Input() perk: Perk = {} as Perk;
  @Input() recommendedTile: RecommendedTile = {} as RecommendedTile;
  @Output() saved = new EventEmitter<boolean>();
  @Output() edit = new EventEmitter<{ active: boolean; object: Category | Product | Perk | RecommendedTile }>();
  @Output() delete = new EventEmitter<boolean>();

  revisions: Observable<Revision[]> = of([]);

  toEdit = {} as Category | Product | Perk | RecommendedTile;
  editView = false;
  type = '';
  dateSort = false;
  editorSort = false;
  commentSort = false;

  constructor(
    private router: Router,
    private revisionService: RevisionService,
  ) {
    this.buttonBar = !!this.router.url.match('^/create/*');
    this.editView = !!this.router.url.match('^/*/edit');
  }

  ngOnInit() {
    this.setValues();
  }

  ngOnChanges() {
    this.setValues();
  }

  saveFunction(activated: boolean) {
    this.saved.emit(activated);
  }

  editFunction(activated: boolean) {
    this.edit.emit({ active: activated, object: this.toEdit });
  }

  deleteFunction(activated: boolean) {
    this.delete.emit(activated);
  }

  setValues() {
    if (this.router.url.match('^/category/*')) {
      this.toEdit = this.category as Category;
      this.type = 'category';
      if (this.category.id) this.revisions = this.revisionService.getRevisions('categories', this.category.id);
    } else if (this.router.url.match('^/product/*')) {
      this.toEdit = this.product as Product;
      this.type = 'product';
      if (this.product.id) this.revisions = this.revisionService.getRevisions('products', this.product.id);
    } else if (this.router.url.match('^/recommended/*')) {
      this.toEdit = this.recommendedTile as RecommendedTile;
      this.type = 'recommendedTiles';
      if (this.recommendedTile.id) this.revisions = this.revisionService.getRevisions('recommendedTiles', this.recommendedTile.id);
    } else {
      this.toEdit = this.perk as Perk;
      this.type = 'perk';
      if (this.perk.id) this.revisions = this.revisionService.getRevisions('perks', this.perk.id);
    }
  }
}
