import { Icon } from './icon';
import { Rule } from './rule';

export interface Perk {
  id: string;
  created: number;
  lastUpdated: number;
  navigationId: number;
  perkName: string;
  analyticsName: string;
  description: string;
  icon: Icon;
  mobileUrl: string;
  webUrl: string;
  type: string;
  ecnEnabled: boolean;
  enabled: boolean;
  effectiveDate: string;
  rolloutPercentage: string;
  displayInMobile: boolean;
  displayInWeb: boolean;
  productForDisplay: string;
  productId: string;
  productOrderOfDisplay: number;
  orderOfDisplay: number;
  rules: Rule[];
  userName: string;
  userId: string;
  revisionComment?: string;
}

export enum BenefitType {
  Perk = 'perk',
  Reward = 'reward',
}
