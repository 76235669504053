import { HomeAge } from './homeAge';

export interface Rule {
  categoryId: string;
  productId: string;
  requiredPolicies: [];
  isNA: boolean;
  tenure: {
    comparison: string;
    value: number;
    display: string;
  };
  homeAge?: HomeAge;
  ratedState: string[];
}
