import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, DefaultUrlSerializer, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription, of } from 'rxjs';
import { CategoryService } from '@services/category.service';
import { RevisionService } from '@services/revision.service';
import { ICONS, IIQ_LINK } from '@shared/constants';
import {
  defaultMaxDate,
  effectiveDateValidator,
  locationValidator,
  revisionValidator,
  rolloutPercentageValidator,
  shouldDisplayError,
} from 'src/app/shared/validators';
import { Category } from '../../shared/types/categories';
import { Perk } from '../../shared/types/perks';
import { Product } from '../../shared/types/products';
import { RecommendedTile } from '../../shared/types/recommendedTile';
import { Revision } from '../../shared/types/revision';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit, OnDestroy {
  urlSerializer = new DefaultUrlSerializer();
  categories: Category[] | undefined = undefined;
  buttonBar = true;
  isCreate = false;
  hideForm = false;
  readOnly = true;
  openModal = false;
  openRequestErrorModal = false;
  hideOrderOfDisplay = false;
  link = IIQ_LINK;
  category: Category = {} as Category;
  selectedCategory = '';
  icons = ICONS;
  revisions: Observable<Revision[]> = of([]);
  maxDate: string | null = defaultMaxDate;
  shouldDisplayError = shouldDisplayError;
  private subscriptions: Subscription = new Subscription();
  hasUnsavedChanges = true;
  openNavModal = false;
  navObject = { object: {} };
  navPath = '';

  constructor(
    private router: Router,
    private categoryService: CategoryService,
    private location: Location,
    private route: ActivatedRoute,
    private revisionService: RevisionService,
    public oidcSecurityService: OidcSecurityService,
  ) {
    const createCheck = this.router.url.match('^/create/*') != null;
    this.buttonBar = createCheck;
    this.isCreate = createCheck;
  }

  categoryForm = new FormGroup({
    categoryName: new FormControl(''),
    subtext: new FormControl(''),
    icon: new FormControl({ value: '', disabled: false }),
    enabled: new FormControl(false),
    effectiveDate: new FormControl('', effectiveDateValidator),
    rolloutPercentage: new FormControl<number | string>('', rolloutPercentageValidator),
    tileDisplay: new FormControl(false),
    locationGroup: new FormGroup(
      {
        displayInMobile: new FormControl(false),
        displayInWeb: new FormControl(false),
      },
      locationValidator,
    ),
  });

  revisionForm = new FormGroup({
    revisionComment: new FormControl('', revisionValidator),
  });

  handleTileDisplayChange() {
    const tileDisplay = this.categoryForm.get('tileDisplay');

    tileDisplay?.valueChanges.subscribe((value) => {
      this.hideOrderOfDisplay = value ?? false;
    });
  }

  toggleCheckbox(control: FormControl) {
    control.patchValue(!control.value);
    control.markAsTouched();
    this.openModal = false;
  }

  modalCancelToggle() {
    this.categoryForm.controls.enabled.patchValue(true);
    this.openModal = false;
  }

  openStatusModal(event: MouseEvent) {
    if (this.categoryForm.controls['enabled'].value) {
      event.preventDefault();
      this.openModal = true;
    }
  }

  async edit(editObject: { active: boolean; object: Category | Product | Perk | RecommendedTile }) {
    const category = this.category ? this.category : (editObject.object as Category);
    if (editObject.active) {
      this.router.navigateByUrl('/category/edit', { state: category });
    }
  }

  async nav(navObject: { object: Category | Product | Perk | RecommendedTile }) {
    if ((this.router.url.match('^/category/edit') || this.isCreate) && this.categoryForm.touched) {
      this.openNavModal = true;
      this.navObject.object = navObject.object;
    } else {
      this.hasUnsavedChanges = false;
      this.category = navObject.object as Category;
      if (this.category.id && this.readOnly) this.revisions = this.revisionService.getRevisions('categories', this.category.id);
      this.highlightCategoryCheck();
    }
  }

  async save(activated: boolean) {
    if (activated) {
      const { locationGroup, ...categoryForm } = this.categoryForm.value;
      this.category = { ...this.category, ...categoryForm, ...locationGroup } as Category;
      this.category.icon = { type: this.categoryForm.get('icon')?.value ? 'name' : '', data: this.categoryForm.get('icon')?.value ?? '' };
      [...Object.values(this.categoryForm.controls), ...Object.values(this.revisionForm.controls)].forEach((c: AbstractControl) => {
        c.markAsDirty();
        c.markAsTouched();
        c.updateValueAndValidity();
      });
      if (this.categoryForm.valid && (this.revisionForm.valid || this.isCreate)) {
        if (this.category.tileDisplay) {
          this.category.orderOfDisplay = undefined;
        } else if (this.categories) {
          this.categories.forEach((current, index) => {
            if (current.orderOfDisplay && this.categories) {
              if (this.categories.indexOf(current) != current.orderOfDisplay - 1) {
                index += 1;
                current.orderOfDisplay = index;
                if (current.id != this.category.id && current.id != '-1') {
                  const revisionComment = 'Updated categories order of display';
                  this.subscriptions.add(this.categoryService.saveCategory(current, revisionComment).subscribe());
                } else {
                  this.category.orderOfDisplay = index;
                }
              }
            }
          });
        }
        const revisionComment = this.revisionForm.value.revisionComment as string;
        (window as any).requestIndicator.show();
        this.subscriptions.add(
          this.categoryService.saveCategory(this.category, revisionComment).subscribe({
            next: (category) => {
              this.category = category;
              this.hasUnsavedChanges = false;
              this.buttonBar = false;
              this.highlightCategoryCheck();
              (window as any).requestIndicator.hide();
              this.router.navigateByUrl('/category/view', { state: this.category });
            },
            error: () => {
              (window as any).requestIndicator.hide();
              this.openRequestErrorModal = true;
            },
          }),
        );
      }
    }
  }

  async delete(activated: boolean) {
    if (activated) {
      (window as any).requestIndicator.show();
      this.subscriptions.add(
        this.categoryService.deleteCategory(this.category).subscribe({
          next: () => {
            (window as any).requestIndicator.hide();
            this.router.navigateByUrl(`/home`);
          },
          error: () => {
            (window as any).requestIndicator.hide();
            this.openRequestErrorModal = true;
          },
        }),
      );
      this.reorderCategories();
    }
  }

  reorderCategories() {
    if (this.categories) {
      this.categories.forEach((current) => {
        if (current.orderOfDisplay && this.category.orderOfDisplay) {
          if (this.category.orderOfDisplay <= current.orderOfDisplay) {
            current.orderOfDisplay = current.orderOfDisplay - 1;
            if (current.id != this.category.id && current.id != '-1') {
              const revisionComment = 'Changed display order';
              this.subscriptions.add(this.categoryService.saveCategory(current, revisionComment).subscribe());
            }
          }
        }
      });
    }
  }

  ngOnInit() {
    this.subscriptions.add(
      this.categoryService.$categories.subscribe((res) => {
        this.categories = res.filter((value) => value.orderOfDisplay).sort((a, b) => (a.orderOfDisplay ?? 0) - (b.orderOfDisplay ?? 0));
        this.checkRoute(this.categories);
        this.setNewCategory();
      }),
    );
    this.readOnly = sessionStorage.getItem('readOnly') === 'false' ? false : true;
    this.category = this.location.getState() as Category;
    this.highlightCategoryCheck();
    if (this.category.id && this.readOnly) this.revisions = this.revisionService.getRevisions('categories', this.category.id);
    this.editCheck();
    this.handleTileDisplayChange();
  }

  checkRoute(categories: Category[]) {
    if (this.router.url.match('^/category/view') && !this.category.categoryName) {
      const name = this.route.snapshot.paramMap.get('name');

      const foundCategory = categories.find((category) => {
        return category.categoryName === name;
      });

      if (foundCategory) {
        this.category = foundCategory;
      } else {
        this.router.navigateByUrl('');
      }
    }
  }

  editCheck() {
    if (this.category != null && this.router.url.match('^/category/edit')) {
      this.buttonBar = true;
      setTimeout(() => {
        this.categoryForm.setValue({
          categoryName: this.category.categoryName,
          subtext: this.category.subtext,
          icon: this.category.icon.data,
          enabled: this.category.enabled,
          effectiveDate: this.category.effectiveDate,
          rolloutPercentage: this.category.rolloutPercentage,
          tileDisplay: this.category.tileDisplay || false,
          locationGroup: {
            displayInMobile: this.category.displayInMobile || false,
            displayInWeb: this.category.displayInWeb || false,
          },
        });
      });
    } else {
      // prevent checkbox toggling animation on edit page load
      // by defaulting to false and then setting to true if not edit mode
      setTimeout(() => this.categoryForm.controls.enabled.setValue(true));
    }
  }

  drop(event: CdkDragDrop<Category[]>) {
    moveItemInArray(this.categories ?? [], event.previousIndex, event.currentIndex);
  }

  onBlur() {
    if (this.router.url.match('^/category/edit')) {
      this.editCategoryNameCheck();
    } else {
      this.createCategoryNameCheck();
    }
  }

  createCategoryNameCheck() {
    if (this.router.url.match('^/create/category') && this.categories) {
      this.categories.forEach((current) => {
        if (this.categoryForm.value.categoryName === '') {
          this.categoryForm.value.categoryName = 'New Category';
        }
        if (current.id === '-1') {
          current.categoryName = this.categoryForm.value.categoryName as string;
        }
      });
    }
  }

  setNewCategory() {
    if (this.router.url.match('^/create/category') && this.categories) {
      const Category = {
        ...this.category,
        id: '-1',
        categoryName: 'New Category',
        orderOfDisplay: this.categories.length - 1,
      };
      this.categories.push(Category);
    }
  }

  editCategoryNameCheck() {
    if (this.categories) {
      this.categories.forEach((current) => {
        if (this.categoryForm.value.categoryName === '') {
          this.categoryForm.value.categoryName = 'New Category';
        }
        if (current.id === this.category.id) {
          current.categoryName = this.categoryForm.value.categoryName as string;
        }
      });
    }
  }

  highlightCategoryCheck() {
    this.selectedCategory = this.category.id;
  }

  selectedIcon(icon: any) {
    try {
      return this.category.icon.data === icon.data;
    } catch (error) {
      return false;
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.categoryForm.dirty ? this.confirmModal() : (this.hasUnsavedChanges = false);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: any) {
    if (this.categoryForm.dirty) {
      event.preventDefault();
    }
  }
  confirmModal() {
    const userChoice = confirm('Are you sure you want to leave? Changes made may not be saved.');
    userChoice ? (this.hasUnsavedChanges = false) : this.hasUnsavedChanges;
  }

  urlPath(path: string) {
    this.navPath = path;
  }

  turnOffRouteGuard() {
    this.hasUnsavedChanges = false;
    this.router.navigateByUrl(`/${this.navPath}/view`, { state: this.navObject.object });
  }

  resetModal() {
    this.openNavModal = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
