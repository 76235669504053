export type PreSignedStorageResponse = {
  url: string;
  contentType: string;
  fileName: string;
};

export type StorageRequest = {
  buffer: ArrayBufferLike;
  contentType: string;
  url: string;
};
