import { Category } from './categories';
import { Perk } from './perks';
import { Product } from './products';

export interface Revision {
  lastUpdated: number;
  revisionComment: string;
  typeId: string;
  userId: string;
  created: number;
  userName: string;
  id: string;
  originalObject?: Category | Product | Perk;
  updatedObject?: Category | Product | Perk;
  type: string;
}
