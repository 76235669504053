import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { CategoryComponent } from './components/category/category.component';
import { ProductComponent } from './components/product/product.component';
import { PerkComponent } from './components/perk/perk.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { SsoAuthorizationComponent } from './components/sso-authorization/sso-authorization.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { RecommendedTileComponent } from './components/recommended/recommended.component';
import { AuthGuard } from './shared/auth-guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  {
    path: 'create/category',
    component: CategoryComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    canDeactivate: [(component: CategoryComponent) => !component.hasUnsavedChanges],
  },
  {
    path: 'create/product',
    component: ProductComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    canDeactivate: [(component: ProductComponent) => !component.hasUnsavedChanges],
  },
  {
    path: 'create/perk',
    component: PerkComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    canDeactivate: [(component: PerkComponent) => !component.hasUnsavedChanges],
  },
  {
    path: 'create/recommended',
    component: RecommendedTileComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    canDeactivate: [(component: RecommendedTileComponent) => !component.hasUnsavedChanges],
  },
  {
    component: UnauthorizedComponent,
    path: 'unauthorized',
  },
  {
    component: SsoAuthorizationComponent,
    path: 'sso-authorization',
  },
  {
    path: 'category',
    children: [
      { path: 'view', pathMatch: 'full', component: CategoryComponent, canActivate: [AutoLoginPartialRoutesGuard, AuthGuard] },
      {
        path: 'edit',
        pathMatch: 'full',
        component: CategoryComponent,
        canActivate: [AutoLoginPartialRoutesGuard, AuthGuard],
        canDeactivate: [(component: CategoryComponent) => !component.hasUnsavedChanges],
      },
      { path: '**', component: ErrorComponent, data: { errorMessage: 'The page does not exist.' } },
    ],
  },
  {
    path: 'product',
    children: [
      { path: 'view', pathMatch: 'full', component: ProductComponent, canActivate: [AutoLoginPartialRoutesGuard, AuthGuard] },
      {
        path: 'edit',
        pathMatch: 'full',
        component: ProductComponent,
        canActivate: [AutoLoginPartialRoutesGuard, AuthGuard],
        canDeactivate: [(component: ProductComponent) => !component.hasUnsavedChanges],
      },
      { path: '**', component: ErrorComponent, data: { errorMessage: 'The page does not exist.' } },
    ],
  },
  {
    path: 'perk',
    children: [
      { path: 'view', pathMatch: 'full', component: PerkComponent, canActivate: [AutoLoginPartialRoutesGuard, AuthGuard] },
      {
        path: 'edit',
        pathMatch: 'full',
        component: PerkComponent,
        canActivate: [AutoLoginPartialRoutesGuard, AuthGuard],
        canDeactivate: [(component: PerkComponent) => !component.hasUnsavedChanges],
      },
      { path: '**', component: ErrorComponent, data: { errorMessage: 'The page does not exist.' } },
    ],
  },
  {
    path: 'recommended',
    children: [
      { path: 'view', pathMatch: 'full', component: RecommendedTileComponent, canActivate: [AutoLoginPartialRoutesGuard, AuthGuard] },
      {
        path: 'edit',
        pathMatch: 'full',
        component: RecommendedTileComponent,
        canActivate: [AutoLoginPartialRoutesGuard, AuthGuard],
        canDeactivate: [(component: RecommendedTileComponent) => !component.hasUnsavedChanges],
      },
      { path: '**', component: ErrorComponent, data: { errorMessage: 'The page does not exist.' } },
    ],
  },
  { path: '**', component: ErrorComponent, data: { errorMessage: 'The page does not exist.' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
