<bolt-waiting-indicator id="requestIndicator" fullscreen starthidden></bolt-waiting-indicator>
<div class="bolt-container-fluid" style="padding-bottom: 50px">
  <div class="bolt-row">
    <div class="bolt-col-sm-3">
      <app-nav (nav)="nav($event)" (urlPath)="urlPath($event)"></app-nav>
    </div>
    <div class="bolt-col">
      <div *ngIf="products && categories">
        <div *ngIf="!readOnly; else displayAlert">
          <app-button-bar
            [buttonBar]="buttonBar"
            [perk]="perk"
            (saved)="save($event)"
            (edit)="edit($event)"
            (delete)="delete($event)"
          ></app-button-bar>
        </div>

        <ng-template #displayAlert>
          <bolt-button-bar class="bolt-background-white">
            <bolt-button slot="back" type="tertiary" iconleft="clock" onclick="window.perkRevisionModal.openModal()"
              >View Revision History</bolt-button
            >
          </bolt-button-bar>
          <bolt-notification type="info"
            >You can only view the details. To make changes, request the owner for edit access in <a [href]="link">IIQ</a></bolt-notification
          >
        </ng-template>

        <div class="container" *ngIf="buttonBar; else viewField" [formGroup]="perkForm">
          <h3 class="bolt-heading-sm">Details</h3>
          <div>
            <bolt-textfield
              (click)="nameClick()"
              (blur)="onBlur()"
              inputsize="100"
              class="bolt-space-bottom-lg"
              label="Name"
              maxLength="100"
              [error]="shouldDisplayError(perkForm.controls.perkName, 'required') ? 'This field is required' : ''"
              required
              ngDefaultControl
              formControlName="perkName"
            >
              <bolt-contextual-help slot="help" type="push" heading="Required"> Perk Name: Max 100 characters </bolt-contextual-help>
            </bolt-textfield>
          </div>
          <div *ngIf="this.isEdit; else createPerk">
            <bolt-textfield label="Analytics Name" class="bolt-space-bottom-lg" disabled [value]="perkForm.value.analyticsName"> </bolt-textfield>
          </div>
          <ng-template #createPerk>
            <div>
              <bolt-textfield
                (click)="analyticsClick()"
                (blur)="onBlur()"
                label="Analytics Name"
                required
                inputsize="40"
                minlength="3"
                maxlength="40"
                [error]="
                  shouldDisplayError(perkForm.controls.analyticsName, 'required')
                    ? 'This field is required and must not contain special characters'
                    : shouldDisplayError(perkForm.controls.analyticsName, 'minlength') ||
                      shouldDisplayError(perkForm.controls.analyticsName, 'maxlength')
                    ? 'This field must be between 3 and 40 characters'
                    : ''
                "
                class="bolt-space-bottom-lg"
                ngDefaultControl
                formControlName="analyticsName"
                [value]="this.perkForm.value.analyticsName"
              >
                <bolt-contextual-help slot="help" type="push" heading="Required">
                  Analytics Name: Enter between 3 and 40 characters. Use '_' for spaces. The words 'ga' and 'google' are not
                  allowed.</bolt-contextual-help
                >
              </bolt-textfield>
            </div>
          </ng-template>

          <div>
            <bolt-textfield
              inputsize="125"
              class="bolt-space-bottom-lg"
              label="Description"
              maxLength="125"
              [error]="perkForm.controls.description.errors?.['message'] || ''"
              ngDefaultControl
              formControlName="description"
              value="{{ perk.description }}"
              instructionalText="This field is required if no URL is entered."
            >
              <bolt-contextual-help slot="help" type="push" heading="Required if No URL Entered">
                Perk Description: Max 125 characters
              </bolt-contextual-help>
            </bolt-textfield>
          </div>
          <div>
            <div ngDefaultControl formControlName="icon">
              <bolt-select
                width="fit-content"
                class="bolt-space-bottom-lg"
                label="Icon"
                (change)="fixIcon()"
                [error]="shouldDisplayError(perkForm.controls.icon, 'required') ? 'This field is required' : ''"
                required
              >
                <bolt-contextual-help slot="help" type="push" required> Icon </bolt-contextual-help>
                <option
                  *ngFor="let icon of this.icons"
                  value="{ &quot;type&quot;: &quot;{{ icon.type }}&quot;, &quot;data&quot;: &quot;{{ icon.data }}&quot; }"
                  [selected]="selectedIcon(icon)"
                >
                  {{ icon.data }}
                </option>
              </bolt-select>
            </div>
          </div>
          <div>
            <bolt-radio-group
              class="bolt-space-bottom-2xl"
              label="Type"
              [formControl]="perkForm.controls.type"
              ngDefaultControl
              required
              [error]="shouldDisplayError(perkForm.controls.type, 'required') ? 'One of these fields is required' : ''"
            >
              <bolt-radio class="float-child" [value]="BenefitType.Perk">Perk</bolt-radio>
              <bolt-radio class="float-child" [value]="BenefitType.Reward">Reward</bolt-radio>
            </bolt-radio-group>
          </div>
          <div class="bolt-row bolt-align-items-center">
            <div class="bolt-col-1">
              <h3 class="bolt-heading-sm" style="margin-right: 20px">Status</h3>
            </div>
            <div class="bolt-col bolt-offset-1">
              <bolt-checkbox-group>
                <div>
                  <bolt-checkbox
                    ngDefaultControl
                    (click)="openStatusModal($event)"
                    (change)="toggleCheckbox(perkForm.controls.enabled)"
                    [formControl]="perkForm.controls.enabled"
                    [value]="perkForm.controls.enabled.value"
                    [checked]="perkForm.controls.enabled.value"
                    >{{ perkForm.controls.enabled.value ? 'On' : 'Off' }}</bolt-checkbox
                  >
                </div>
              </bolt-checkbox-group>
            </div>
          </div>
          <div>
            <bolt-date-picker
              required
              ngDefaultControl
              formControlName="effectiveDate"
              inputsize="20"
              label="Effective Date"
              min="today"
              max="{{ maxDate }}"
              [error]="perkForm.controls.effectiveDate.errors?.['message'] || ''"
              class="bolt-space-bottom-lg"
              pickerposition="right"
              instructionaltext="The date must not be in the past or more than one year in the future."
            >
            </bolt-date-picker>
            <bolt-textfield
              type="number"
              min="0"
              max="100"
              [error]="perkForm.controls.rolloutPercentage.errors?.['message'] || ''"
              inputsize="5"
              class="bolt-space-bottom-lg"
              suffix="%"
              label="Rollout %"
              optionaltext="hide"
              aligninput="right"
              required
              ngDefaultControl
              formControlName="rolloutPercentage"
            ></bolt-textfield>
          </div>
          <div class="bolt-space-top-lg">
            <h3 class="bolt-heading-sm">Display Info</h3>
            <div>
              <bolt-checkbox-group
                class="bolt-space-bottom-2xl"
                label="Location"
                [error]="perkForm.controls.locationGroup.errors?.['message'] || ''"
                required
              >
                <bolt-checkbox
                  ngDefaultControl
                  (change)="toggleCheckbox(perkForm.controls.locationGroup.controls.displayInMobile); displayInMobileEnabled()"
                  [formControl]="perkForm.controls.locationGroup.controls.displayInMobile"
                  class="float-child"
                  name="Mobile"
                  [value]="perkForm.controls.locationGroup.controls.displayInMobile.value"
                  [checked]="perkForm.controls.locationGroup.controls.displayInMobile.value"
                  >Mobile App</bolt-checkbox
                >
                <bolt-checkbox
                  ngDefaultControl
                  (change)="toggleCheckbox(perkForm.controls.locationGroup.controls.displayInWeb); displayInWebEnabled()"
                  [formControl]="perkForm.controls.locationGroup.controls.displayInWeb"
                  class="float-child"
                  name="Web"
                  [value]="perkForm.controls.locationGroup.controls.displayInWeb.value"
                  [checked]="perkForm.controls.locationGroup.controls.displayInWeb.value"
                  >Website</bolt-checkbox
                >
                <bolt-contextual-help slot="help" type="push" heading="Notice">
                  Selection will create new URL Field for either Mobile or Website
                </bolt-contextual-help>
              </bolt-checkbox-group>
            </div>

            <div>
              <div *ngIf="this.perkForm.controls.locationGroup.controls.displayInMobile.value">
                <div>
                  <bolt-textfield
                    inputsize="100"
                    class="bolt-space-bottom-lg"
                    label="Mobile URL"
                    optionaltext="false"
                    [error]="perkForm.controls.mobileUrl.errors?.['message'] || ''"
                    ngDefaultControl
                    formControlName="mobileUrl"
                  >
                    <bolt-contextual-help slot="help" type="push" heading="Required"> Mobile URL: Cannot contain blank spaces </bolt-contextual-help>
                  </bolt-textfield>
                </div>
              </div>
              <div *ngIf="this.perkForm.controls.locationGroup.controls.displayInWeb.value">
                <div>
                  <bolt-textfield
                    inputsize="100"
                    class="bolt-space-bottom-lg"
                    label="Website URL"
                    optionaltext="false"
                    [error]="perkForm.controls.webUrl.errors?.['message'] || ''"
                    ngDefaultControl
                    formControlName="webUrl"
                  >
                    <bolt-contextual-help slot="help" type="push" heading="Required"> Website URL: Cannot contain blank spaces </bolt-contextual-help>
                  </bolt-textfield>
                </div>
              </div>
            </div>

            <div>
              <bolt-checkbox-group label="ECN">
                <bolt-checkbox
                  ngDefaultControl
                  (change)="ecnChanged()"
                  [formControl]="perkForm.controls.ecnGroup.controls.ecnEnabled"
                  [value]="perkForm.controls.ecnGroup.controls.ecnEnabled.value"
                  [checked]="perkForm.controls.ecnGroup.controls.ecnEnabled.value"
                  >Include</bolt-checkbox
                >
                <em>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;URL must be Nationwide</em><br />
              </bolt-checkbox-group>
            </div>
            <br />

            <div>
              <div *ngIf="perkForm.value.productId">
                <div class="bolt-body-copy-md"><strong>Order of Display</strong><br /></div>
                <div cdkDropList class="drop-list" label="Order of Display" (cdkDropListDropped)="drop($event)">
                  <div class="drop-box" *ngFor="let perk of productBasedPerkArray" cdkDrag>
                    <div class="inner-box">
                      {{ perk.perkName }}
                      <bolt-icon class="icon" name="menu"></bolt-icon>
                    </div>
                  </div>
                </div>
                <p style="font-size: 12px">Drag to reorder.</p>
              </div>
            </div>

            <div>
              <div formArrayName="rules">
                <div *ngFor="let rulesForm of this.perkForm.controls.rules.controls; let index = index" [formGroupName]="index">
                  <div>
                    <h3 class="bolt-heading-sm">Rule {{ index + 1 }}</h3>
                    <div>
                      <bolt-select
                        ngDefaultControl
                        [formControl]="rulesForm.controls.categoryId"
                        class="bolt-space-bottom-xl"
                        label="Category for Display"
                        width="fit-content"
                        required
                        [error]="shouldDisplayError(rulesForm.controls.categoryId, 'required') ? 'This field is required' : ''"
                      >
                        <option *ngFor="let category of filteredCategories" [value]="category.id">{{ category.categoryName }}</option>
                      </bolt-select>
                    </div>
                    <div *ngIf="rulesForm.value.categoryId && sortedProducts[rulesForm.value.categoryId]" style="margin-bottom: 20px">
                      <bolt-select
                        (change)="selectedProduct(product)"
                        width="fit-content"
                        class="bolt-space-bottom-lg"
                        required
                        [error]="shouldDisplayError(rulesForm.controls.productId, 'required') ? 'This field is required' : ''"
                        label="Product for Display"
                        ngDefaultControl
                        [formControl]="rulesForm.controls.productId"
                      >
                        <option *ngFor="let product of sortedProducts[rulesForm.value.categoryId]" [value]="product.id">
                          {{ product.productName }}
                        </option>
                      </bolt-select>
                    </div>
                    <div>
                      <div class="bolt-body-copy-md"><strong>Required Policies</strong><br /></div>
                      <bolt-checkbox-group ngDefaultControl formControlName="isNA" label="N/A" required>
                        <bolt-checkbox
                          [value]="this.perkForm.value.rules?.[index]?.isNA"
                          [checked]="this.perkForm.value.rules?.[index]?.isNA"
                          (change)="naDisabled(index)"
                        >
                          N/A
                        </bolt-checkbox>
                      </bolt-checkbox-group>
                      <div class="scrollable-container">
                        <div class="checkbox-wrapper">
                          <div *ngFor="let policy of this.policiesRequired">
                            <div class="bolt-col">
                              <bolt-checkbox-group [label]="policy.name" required [disabled]="rulesForm.controls.isNA.value">
                                <bolt-checkbox
                                  [checked]="checkedPolicies(policy, index, true)"
                                  (change)="setSelectedPolicyValue(policy, index, true)"
                                  value="{&quot;{{ policy.name }}&quot;: true}"
                                  >Required</bolt-checkbox
                                >
                                <bolt-checkbox
                                  [checked]="checkedPolicies(policy, index, false)"
                                  (change)="setSelectedPolicyValue(policy, index, false)"
                                  value="{&quot;{{ policy.name }}&quot;: false}"
                                  >Not Carried</bolt-checkbox
                                >
                              </bolt-checkbox-group>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="shouldDisplayError(rulesForm.controls.requiredPolicies, 'required')">
                        <p style="color: red">*Please Enter a Required Policy</p>
                      </div>
                    </div>
                    <br />
                    <div ngDefaultControl formControlName="tenure">
                      <div>
                        <bolt-select
                          ngDefaultControl
                          (change)="fixTenure(index)"
                          width="fit-content"
                          class="bolt-space-bottom-lg"
                          label="Tenure of policy needed"
                          required
                          [error]="shouldDisplayError(rulesForm.controls.tenure, 'required') ? 'This field is required' : ''"
                        >
                          <option
                            *ngFor="let tenure of this.tenureArray"
                            value="{ &quot;value&quot;: {{ tenure.value }}, &quot;comparison&quot;: &quot;{{
                              tenure.comparison
                            }}&quot;, &quot;display&quot;: &quot;{{ tenure.display }}&quot; }"
                            [selected]="selectedTenure(index, tenure)"
                          >
                            {{ tenure.display }}
                          </option>
                        </bolt-select>
                      </div>
                    </div>
                    <div *ngIf="checkedPolicies({ name: 'Home' }, index, true)">
                      <bolt-select
                        ngDefaultControl
                        formControlName="homeAge"
                        class="bolt-space-bottom-xl"
                        label="Home Age"
                        width="fit-content"
                        required
                        [error]="shouldDisplayError(rulesForm.controls.homeAge, 'required') ? 'Please make a selection' : ''"
                      >
                        <option
                          *ngFor="let homeAgeOption of homeAgeOptions"
                          [selected]="selectedHomeAge(homeAgeOption, index)"
                          [value]="homeAgeOption | json"
                        >
                          {{ homeAgeOption.title }}
                        </option>
                      </bolt-select>
                    </div>
                    <div class="bolt-row">
                      <div class="bolt-col bolt-col-lg-2">
                        <bolt-autocomplete
                          (onBlur)="addRatedState(index)"
                          (change)="addRatedState(index)"
                          class="bolt-space-bottom-xl"
                          ngDefaultControl
                          [(ngModel)]="newState"
                          [ngModelOptions]="{ standalone: true }"
                          inputsize="15"
                          mincharacters="1"
                          label="Rated State(s)"
                          (keypress)="printKey($event, index)"
                          (keydown)="otherKey($event)"
                          [options]="matchStates"
                          required
                        >
                          <bolt-contextual-help slot="help" type="push" heading="Rated State(s)">
                            Please enter a Valid State (ex. Ohio, North Dakota...). Enter "All States" to add all states.
                          </bolt-contextual-help>
                        </bolt-autocomplete>
                      </div>
                      <div class="bolt-col">
                        <strong>Added States: </strong><br />
                        <mat-chip-row
                          class="bolt-space-bottom-xl"
                          *ngFor="let state of getState(index)"
                          (removed)="remove(state, index)"
                          [editable]="true"
                          [aria-description]="'press enter to edit ' + state"
                        >
                          {{ state }}
                          <button matChipRemove [attr.aria-label]="'remove ' + state">
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip-row>
                        <div *ngIf="shouldDisplayError(rulesForm.controls.ratedState, 'required')">
                          <p style="color: red">*Please Enter a Valid State</p>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="this.numRule !== 1">
                      <bolt-button
                        class="bolt-background-cherry-red"
                        iconleft="trash"
                        color="white"
                        type="tertiary"
                        (click)="getIndex(index)"
                        onclick="window.deleteRuleModal.openModal()"
                        >Delete Rule {{ index + 1 }}</bolt-button
                      >
                    </div>
                  </div>
                  <hr *ngIf="this.numRule > 1" style="width: 80%; margin-left: 0; margin-bottom: 24px" />
                </div>
              </div>
            </div>
            <div *ngIf="this.numRule < 5; else addHidden">
              <bolt-button class="bolt-space-bottom-xl" type="tertiary" (click)="addRule()">+ Add another rule</bolt-button>
            </div>
            <ng-template #addHidden>
              <bolt-button class="bolt-space-bottom-xl" type="tertiary" disabled>+ Add another rule</bolt-button>
            </ng-template>

            <div *ngIf="!isCreate" [formGroup]="revisionForm">
              <div>
                <bolt-textarea
                  inputsize="100"
                  class="bolt-space-bottom-lg"
                  label="Revision comments"
                  maxLength="100"
                  required
                  [error]="this.revisionForm.controls.revisionComment.errors?.['message'] || ''"
                  ngDefaultControl
                  formControlName="revisionComment"
                  cols="40"
                ></bolt-textarea>
                <p>Enter between 15 and 100 characters</p>
              </div>
            </div>
          </div>
        </div>

        <ng-template #viewField>
          <h3 class="bolt-heading-sm">Details</h3>
          <div class="bolt-body-copy-lg"><strong>Name</strong><br /></div>
          <p>{{ perk.perkName }}</p>
          <div class="bolt-body-copy-lg"><strong>Analytics Name</strong><br /></div>
          <p>{{ perk.analyticsName }}</p>
          <div class="bolt-body-copy-lg"><strong>Description</strong><br /></div>
          <p>{{ perk.description }}</p>
          <div class="bolt-body-copy-lg"><strong>Icon</strong><br /></div>
          <p>{{ perk.icon.data }}</p>
          <div class="bolt-body-copy-lg"><strong>Type</strong><br /></div>
          <p>{{ perk.type }}</p>
          <hr />
          <div class="bolt-row bolt-align-items-center">
            <div class="bolt-col-1">
              <h3 class="bolt-heading-sm" style="margin-right: 20px">Status</h3>
            </div>
            <div class="bolt-col bolt-offset-1">
              <bolt-checkbox-group>
                <div *ngIf="perk.enabled; else off">
                  <bolt-checkbox checked="true" disabled>On</bolt-checkbox>
                </div>
                <ng-template #off>
                  <bolt-checkbox disabled>Off</bolt-checkbox>
                </ng-template>
              </bolt-checkbox-group>
            </div>
          </div>
          <div class="bolt-body-copy-lg"><strong>Effective Date</strong><br /></div>
          <p>{{ perk.effectiveDate }}</p>
          <div class="bolt-body-copy-lg"><strong>Rollout %</strong><br /></div>
          <p>{{ perk.rolloutPercentage }}</p>
          <hr />

          <h3 class="bolt-heading-sm">Display Info</h3>
          <div class="bolt-body-copy-lg"><strong>Location</strong><br /></div>
          <div class="bolt-row">
            <div class="bolt-col-3">
              <p *ngIf="perk.displayInMobile">Mobile App</p>
            </div>
            <div class="bolt-col">
              <p *ngIf="perk.displayInWeb">Website</p>
            </div>
          </div>
          <div *ngIf="perk.mobileUrl">
            <div class="bolt-body-copy-lg"><strong>Mobile URL</strong><br /></div>
            <p>{{ perk.mobileUrl }}</p>
          </div>
          <div *ngIf="perk.webUrl">
            <div class="bolt-body-copy-lg"><strong>Web URL</strong><br /></div>
            <p>{{ perk.webUrl }}</p>
          </div>
          <div class="bolt-body-copy-lg"><strong>ECN</strong><br /></div>
          <div *ngIf="perk.ecnEnabled; else ecnDisabled">
            <p>Included</p>
          </div>
          <ng-template #ecnDisabled>
            <p>Not Included</p>
          </ng-template>
          <div class="bolt-body-copy-lg"><strong>Order of Display</strong><br /></div>
          <ol>
            <li *ngFor="let perk of productBasedPerkArray" [ngClass]="{ highlighted: perk.id === selectedPerk }">{{ perk.perkName }}</li>
          </ol>
          <hr />
          <div *ngFor="let attachedRules of perk.rules; let index = index">
            <h3 class="bolt-heading-sm">Rule {{ index + 1 }}</h3>
            <div class="bolt-body-copy-lg"><strong>Category For Display</strong><br /></div>
            <div *ngFor="let category of categories">
              <div *ngIf="category.id === perk.rules[index].categoryId">
                <p>{{ category.categoryName }}</p>
              </div>
            </div>

            <div class="bolt-body-copy-lg"><strong>Product</strong><br /></div>
            <div *ngFor="let product of products">
              <div *ngIf="product.id === perk.rules[index].productId">
                <p>{{ product.productName }}</p>
              </div>
            </div>

            <div class="bolt-body-copy-lg"><strong>Policies Required</strong><br /></div>
            <div *ngFor="let attachedPolicies of perk.rules[index].requiredPolicies">
              <div *ngFor="let policies of attachedPolicies | keyvalue">
                <p>&#8226; {{ policies.key }}: {{ policies.value }}</p>
              </div>
            </div>
            <div class="bolt-body-copy-lg"><strong>Tenure of policy needed</strong><br /></div>
            <p>{{ perk.rules[index].tenure.display }}</p>
            <div class="bolt-body-copy-lg"><strong>Home Age</strong><br /></div>
            <p>{{ perk.rules[index].homeAge?.title ?? 'None' }}</p>
            <div class="bolt-body-copy-lg"><strong>Rated State(s)</strong><br /></div>
            <span class="bolt-space-bottom-lg" *ngFor="let attachedStates of perk.rules[index].ratedState"> &#8226; {{ attachedStates }} </span>
            <hr />
          </div>
        </ng-template>
      </div>
      <div *ngIf="!categories || !products">
        <bolt-waiting-indicator>Loading...</bolt-waiting-indicator>
      </div>
    </div>
  </div>
</div>

<bolt-modal id="deleteRuleModal" type="question" heading="Remove Rule {{ this.currentIndex + 1 }}?">
  <p>You won't be able to recover this information.</p>
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button
      slot="back"
      type="tertiary"
      color="white"
      class="bolt-background-cherry-red"
      onclick="window.deleteRuleModal.closeModal()"
      (click)="deleteRules(this.currentIndex)"
      >Yes, remove Rule {{ this.currentIndex + 1 }}</bolt-button
    >
    <bolt-button type="primary" slot="forward" onclick="window.deleteRuleModal.closeModal()">Cancel</bolt-button>
  </bolt-button-bar>
</bolt-modal>

<!-- Revision Modal -->
<bolt-modal id="perkRevisionModal" type="content" heading="Revision history">
  <app-revision-table [revisions]="(revisions | async) || []"></app-revision-table>
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button type="primary" slot="forward" onclick="window.perkRevisionModal.closeModal()">Dismiss</bolt-button>
  </bolt-button-bar>
</bolt-modal>

<bolt-modal id="customNavButtonModal" type="question" heading="Are you sure you want to leave?" [open]="this.openNavModal">
  <p>Unsaved changes may be lost.</p>

  <bolt-button-bar slot="modal-button-bar">
    <bolt-button slot="back" (click)="turnOffRouteGuard()" onclick="window.customNavButtonModal.closeModal()">Navigate</bolt-button>
    <bolt-button slot="cancel" (click)="resetModal()" onclick="window.customNavButtonModal.closeModal()">Cancel</bolt-button>
  </bolt-button-bar>
</bolt-modal>

<!-- Confirmation Modal -->
<bolt-modal
  id="questionModal"
  type="question"
  heading="Turn off Perk?"
  (bolt-modal-secondary-button-click)="toggleCheckbox(perkForm.controls.enabled)"
  (bolt-modal-primary-button-click)="modalCancelToggle()"
  primarybutton="No, keep on"
  secondarybutton="Yes, turn off"
  [open]="this.openModal"
>
  <p>This perk will no longer be shown to users</p>
</bolt-modal>

<!-- Request Error Modal -->
<bolt-modal
  id="requestErrorModal"
  type="error"
  heading="Error"
  primarybutton="Close"
  [open]="openRequestErrorModal"
  (bolt-modal-primary-button-click)="openRequestErrorModal = false"
>
  <p>There was an issue processing your request.</p>
</bolt-modal>
